import React, { lazy, useContext, useMemo } from 'react'
import PropTypes, { userType } from 'propTypes'
import { Redirect, Route, Switch } from 'react-router-dom'
import useFlags from 'hooks/useFlags'
import Relogin from 'pages/Authentication/components/Relogin'
import LoginFailed from 'pages/Authentication/components/LoginFailed'
import useAuthorshipSession from 'hooks/useAuthorshipSession'
import ManageProjectDashboard from 'components/ManageProjectDashboard'
import Loading from 'components/Loading'
import Dashboard from 'components/Dashboard'
import Community from 'pages/Community'
import HybridRoutesCreator from 'components/HybridRoutesCreator'
import ExternalRedirect from 'components/ExternalRedirect'
import { HybridRoutesContext } from 'components/HybridRoutesProvider/HybridRoutesProvider'
import EmployeeDashboardRoot from 'pages/EmployeeDashboard/EmployeeDashboardRoot'

const AsyncCampaignPage = lazy(() => import('pages/Campaign'))
const AsyncDisclaimerPage = lazy(() => import('pages/Disclaimer'))
const AsyncAuthentication = lazy(() => import('pages/Authentication'))
const AsyncPasswordResetPage = lazy(() =>
  import('pages/Authentication/PasswordResetPage')
)
const AsyncEligibility = lazy(() => import('pages/Eligibility'))
const AsyncCategorizedEligibility = lazy(() =>
  import('pages/CategorizedEligibility')
)
const AsyncManageProject = lazy(() => import('pages/ManageProject'))
const AsyncProjectDetail = lazy(() => import('pages/ProjectDetail'))
const AsyncUnsubscribe = lazy(() => import('pages/Unsubscribe'))
const AsyncActivate = lazy(() => import('pages/ActivateAccount'))
const AsyncPageNotFound = lazy(() => import('pages/PageNotFound'))
const AsyncLegal = lazy(() => import('pages/Legal'))
const AsyncJohnDeereCallback = lazy(() => import('pages/JohnDeereCallback'))
const SustainabilityReports = lazy(() => import('pages/SustainabilityReports'))
const AsyncGrowerDashboard = lazy(() => import('pages/GrowerDashboard'))
const AsyncGrowerDashboardV2 = lazy(() => import('pages/GrowerDashboardV2'))
const AsyncDashboardRedirect = lazy(() => import('pages/DashboardRedirect'))
const AsyncDeclineInvitation = lazy(() => import('pages/DeclineInvitation'))
const AsyncEnrollFields = lazy(() => import('pages/EnrollFields'))
const AsyncBuildRotations = lazy(() => import('pages/BuildRotations'))
const AsyncCreatePlan = lazy(() => import('pages/CreatePlan'))
const AsyncDocumentBaseline = lazy(() => import('pages/DocumentBaseline'))
const AsyncLogFieldActivity = lazy(() => import('pages/LogFieldActivity'))
const EmailVerificationSuccess = lazy(() =>
  import('pages/Authentication/components/EmailVerificationSuccess')
)

const GotoLogin = ({ location }) => (
  <Redirect
    to={`/login?redirect=${encodeURIComponent(
      location.pathname + location.search
    )}`}
  />
)

GotoLogin.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
    search: PropTypes.string.isRequired
  }).isRequired
}

const getDigitalHubBaseUrl = audience => {
  if (audience?.includes?.('nutrien-digital-dev'))
    return 'https://my.dev.nutrienagsolutions.com'
  else if (audience?.includes?.('nutrien-digital-sit'))
    return 'https://my.sit.nutrienagsolutions.com'
  else if (audience?.includes?.('nutrien-digital-pre'))
    return 'https://my.pre.nutrienagsolutions.com'
  else return 'https://my.nutrienagsolutions.com'
}

const AgribleRoutes = ({ user, isAuthenticated }) => {
  const { authorshipSessionActive } = useAuthorshipSession()
  const flags = useFlags()
  const hybridRoutes = useContext(HybridRoutesContext)
  const canViewEmployeeDashboard =
    !authorshipSessionActive &&
    user?.communityDashboardAccess?.canViewEmployeeDashboard
  // this is to check if the hostname is about.agrible.com
  // we then reroute the user to agrible.com
  // and keep the pathname, search and hash.
  if (window.location.hostname?.includes('about.agrible')) {
    // this is a known working solution but it's sluggish.
    if (window.location.pathname === '/') {
      // if there are no pathname we'll assume that the user
      // just wants to go to about.agrible.com which we want
      // to redirect to the new agrible landing page.
      window.location.href = 'https://www.agrible.com/about'
    } else {
      // else, we'll redirect them to the agrible.com
      // and we'll let the routes handle where the
      // users need to be redirected to
      window.location.href = `https://www.agrible.com${window.location.pathname}${window.location.search}${window.location.hash}`
    }
  }
  const audience = window?.uiConf?.auth0Audience
  const digitalHubBaseUrl = useMemo(() => {
    return getDigitalHubBaseUrl(audience)
  }, [audience])

  return (
    <Switch>
      {flags.homePageRedirect && !isAuthenticated ? (
        <ExternalRedirect
          exact
          from="/"
          to="https://nutrienagsolutions.com/agrible"
          replace
        />
      ) : (
        <Route exact path="/" component={AsyncAuthentication} />
      )}
      <Route
        exact
        path={[
          '/signup',
          '/login',
          '/auth',
          '/legacy-login',
          '/legacy-login/emailsent'
        ]}
        component={AsyncAuthentication}
      />
      <Route
        exact
        path={['/legacy-login/:resetPassword?']}
        component={AsyncPasswordResetPage}
      />

      <Route
        path="/accounts/password/reset/confirm/:id/:token"
        render={({ match }) => (
          <Redirect
            to={`/resetpassword/resetpassword/${match.params.id}/${match.params.token}`}
          />
        )}
      />
      <Route
        path="/resetPassword/:action/:id?/:token?"
        component={AsyncPasswordResetPage}
      />
      <Route exact path="/relogin" component={Relogin} />
      <Route exact path="/login-failed" component={LoginFailed} />
      <Route exact path="/verified" component={EmailVerificationSuccess} />

      <Route exact path="/campaign" component={AsyncCampaignPage} />

      <Route
        path="/sustainability-reports"
        render={({ location, match }) =>
          user ? (
            <SustainabilityReports match={match} />
          ) : isAuthenticated ? (
            <Loading />
          ) : (
            <GotoLogin location={location} />
          )
        }
      />

      <Route
        exact
        path="/dashboard"
        render={({ location, match }) =>
          user ? (
            <AsyncDashboardRedirect match={match} />
          ) : isAuthenticated ? (
            <Loading />
          ) : (
            <GotoLogin location={location} />
          )
        }
      />

      {/* flags.growerDashboardV2 is temporary. We're using DEV as the testing ground of v2. */}
      {flags.sustainabiltityGrowerDashboard &&
        (!authorshipSessionActive ||
          (authorshipSessionActive &&
            flags.nerpAuthorshipForGrowerDashboard)) && (
          <Route
            exact
            path="/grower-dashboard"
            render={({ location, match }) =>
              user ? (
                flags.growerDashboardV2 ? (
                  <AsyncGrowerDashboardV2 match={match} />
                ) : (
                  <AsyncGrowerDashboard match={match} />
                )
              ) : isAuthenticated ? (
                <Loading />
              ) : (
                <GotoLogin location={location} />
              )
            }
          />
        )}

      <Route
        path="/employee-dashboard"
        render={({ location, match }) => (
          <EmployeeDashboardRoot
            user={user}
            canViewEmployeeDashboard={canViewEmployeeDashboard}
            isAuthenticated={isAuthenticated}
            AsyncPageNotFound={AsyncPageNotFound}
            location={location}
            match={match}
            GotoLogin={GotoLogin}
          />
        )}
      />

      {/* when stepper optimization flag is on */}
      {flags.sustainabilityManageProject && flags.stepperOptimization && (
        <Route
          path="/manage-project/:id"
          render={({ location, match }) =>
            user ? (
              <ManageProjectDashboard match={match} />
            ) : isAuthenticated ? (
              <Loading />
            ) : (
              <GotoLogin location={location} />
            )
          }
        />
      )}

      {flags.sustainabilityManageProject && !flags.stepperOptimization && (
        <Route
          exact
          path="/manage-project/:id"
          render={({ location, match }) =>
            user ? (
              <AsyncManageProject match={match} />
            ) : isAuthenticated ? (
              <Loading />
            ) : (
              <GotoLogin location={location} />
            )
          }
        />
      )}

      {/* leaving this here, in case we may make use of it in the future? */}
      {/* {flags.postProjectCommitmentViewState && (
        <Route
          path="/view-project/:id"
          render={({ location }) =>
            user ? (
              <ViewProjectDashboard />
            ) : isAuthenticated ? (
              <Loading />
            ) : (
              <GotoLogin location={location} />
            )
          }
        />
      )} */}

      {flags.sustainabilityManageProject && !flags.stepperOptimization && (
        <Route
          path="/manage-project/:id/select-fields/"
          render={({ location, match }) =>
            user ? (
              <AsyncEnrollFields match={match} />
            ) : isAuthenticated ? (
              <Loading />
            ) : (
              <GotoLogin location={location} />
            )
          }
        />
      )}
      {flags.stepperBuildRotations && !flags.stepperOptimization && (
        <Route
          path="/manage-project/:id/build-rotations/"
          render={({ location, match }) =>
            user ? (
              <AsyncBuildRotations match={match} />
            ) : isAuthenticated ? (
              <Loading />
            ) : (
              <GotoLogin location={location} />
            )
          }
        />
      )}
      {flags.stepper4CreatePlan && !flags.stepperOptimization && (
        <Route
          path="/manage-project/:id/create-plan/"
          render={({ location, match }) =>
            user ? (
              <AsyncCreatePlan match={match} />
            ) : isAuthenticated ? (
              <Loading />
            ) : (
              <GotoLogin location={location} />
            )
          }
        />
      )}
      {flags.stepperDocumentBaseline && !flags.stepperOptimization && (
        <Route
          path="/manage-project/:id/document-baseline/"
          render={({ location, match }) =>
            user ? (
              <AsyncDocumentBaseline match={match} />
            ) : isAuthenticated ? (
              <Loading />
            ) : (
              <GotoLogin location={location} />
            )
          }
        />
      )}
      {flags.manageProjectLogFieldActivityEnabled &&
        !flags.stepperOptimization && (
          <Route
            path="/manage-project/:id/log-field-activity/"
            render={({ location, match }) =>
              user ? (
                <AsyncLogFieldActivity match={match} />
              ) : isAuthenticated ? (
                <Loading />
              ) : (
                <GotoLogin location={location} />
              )
            }
          />
        )}
      {flags.sustainabilityProjectDetail &&
        (!authorshipSessionActive ||
          (authorshipSessionActive &&
            flags.nerpAuthorshipForGrowerDashboard)) && (
          <Route
            exact
            path="/grower-dashboard/project-detail/:id"
            render={({ location, match }) =>
              user ? (
                <AsyncProjectDetail match={match} />
              ) : isAuthenticated ? (
                <Loading />
              ) : (
                <GotoLogin location={location} />
              )
            }
          />
        )}
      <Route exact path="/disclaimer" component={AsyncDisclaimerPage} />
      <Route
        path="/eligibility"
        render={props =>
          flags.eligibilityScreenerCanadaSupport ? (
            <AsyncCategorizedEligibility {...props} />
          ) : (
            <AsyncEligibility {...props} />
          )
        }
      />
      <Redirect from="/international" to="/signup?signup_view=enter_code" />
      <Redirect from="/upgrade" to="/signup" />
      <Route path="/accounts/activate/:token" component={AsyncActivate} />
      <Route
        path="/accounts/unsubscribe/:id/:token"
        component={AsyncUnsubscribe}
      />
      <Route
        path="/accounts/decline-community-invites/:token"
        component={AsyncDeclineInvitation}
      />

      <Redirect from="/privacy" to="/termsandconditions#privacy" />
      <Route path="/termsandconditions" component={AsyncLegal} />
      <Route
        path="/:viewMode(cards|maps)/:farmItemLevel(account|orgs|org_group|enterprise_groups|farms|fields)/:farmItemId"
        render={({ location, match }) =>
          user ? (
            <Dashboard match={match} />
          ) : isAuthenticated ? (
            <Loading />
          ) : (
            <GotoLogin location={location} />
          )
        }
      />
      {flags?.deprecatedCommunityFeature && !authorshipSessionActive && (
        <Route
          path="/community"
          render={({ location, match }) =>
            user ? (
              <Community match={match} />
            ) : isAuthenticated ? (
              <Loading />
            ) : (
              <GotoLogin location={location} />
            )
          }
        />
      )}
      <Route
        path="/fieldimport/jdimport-update-token"
        component={AsyncJohnDeereCallback}
      />

      {hybridRoutes.map(({ isEnabled, requiresAuth, routes }) => {
        return (
          <Route
            path={routes[0].path}
            key={routes[0].path}
            render={({ location }) => {
              if (requiresAuth && !isAuthenticated) {
                return <GotoLogin location={location} />
              }

              return (
                <HybridRoutesCreator
                  routes={routes}
                  routesEnabled={!!isEnabled}
                />
              )
            }}
          />
        )
      })}

      <Redirect from="/products*" to="/" />

      <ExternalRedirect
        exact
        from="/about"
        to="https://nutrienagsolutions.com/agrible"
      />

      <ExternalRedirect
        exact
        replace
        from="/nutrien-digital-hub"
        to={digitalHubBaseUrl}
      />

      <ExternalRedirect
        exact
        from="/contact-us"
        to={`${digitalHubBaseUrl}/contact`}
      />

      <ExternalRedirect
        exact
        from="/agnews"
        to="https://nutrienagsolutions.com/blog"
      />

      <ExternalRedirect
        from="/agnews/category/Expert+Insights"
        to="https://nutrienagsolutions.com/agrible"
      />

      <ExternalRedirect
        from="/agnews/category/Agrible+News"
        to="https://nutrienagsolutions.com/agrible"
      />
      {/* Last route will show the 404 page not found */}
      <Route path="*" component={AsyncPageNotFound} />
    </Switch>
  )
}

AgribleRoutes.propTypes = {
  user: userType,
  isAuthenticated: PropTypes.bool.isRequired
}

AgribleRoutes.defaultProps = {
  user: null
}

export default AgribleRoutes
