import React from 'react'
import PropTypes from 'prop-types'
import * as Sentry from '@sentry/browser'

// Calling it here so it initializes when the module loads, before the app renders.
Sentry.init({
  environment: window.uiConf.sentryEnv,
  dsn: window.uiConf.sentryDsn
})

export default class SentryLogger extends React.Component {
  static propTypes = {
    children: PropTypes.any
  }

  componentDidCatch(error, errorInfo) {
    Sentry.withScope(scope => {
      Object.keys(errorInfo).forEach(key => {
        scope.setExtra(key, errorInfo[key])
      })
      Sentry.captureException(error)
    })
  }

  render() {
    return this.props.children
  }
}
