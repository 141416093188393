export const ALL_CROPPING_SEASONS_QUERY = `
  query ListAllCroppingSeasons($profileUuid: String!) {
    listAllCroppingSeasons(profileUuid: $profileUuid) {
      season
      fields {
        fieldUuid
        farmUuid
        organizationUuid
      }
    }
  }
`

export const CROPPING_SEASONS_QUERY = `
query ListCroppingSeasons($fieldId: String!, $profileUuid: String!, $unit: Units!, $includePlanSeasons: Boolean) {
  listCroppingSeasons(fieldId: $fieldId, profileUuid: $profileUuid, unit: $unit, includePlanSeasons: $includePlanSeasons) {
    id
    crop {
      id
      name
      isCoverCrop
      isFsSupported
      isSustainabilitySupportedCrop
      isSilage
      hasAlternateHarvest
      standardMoisturePercentage
    }
    specificCrop {
      id
      name
    }
    startDate
    endDate
    isFallow
    isCoverCrop
    bestPracticesAuthor {
      name
      uuid
    }
    drainageStatus
    isMonitoredNitrogenDeficiency
    tillageMethod
    productionAreaValue
    fieldAreaValue
    fieldAreaUnit
    conservationPractices {
      id
      name
    }
    fieldCharacteristics
    canEdit
    canDelete
    energySource
    irrigation {
      irrigationSystemOn
      irrigationSystemType
      irrigatedAreaValue
      irrigatedAreaUnit
      type: irrigationSystemType
      systemCapacityValue
      systemCapacityUnit
      waterSource
      fuelType
      floodType
      energySource
      pumpPressureValue
      pumpPressureUnit
      pumpDepthValue
      pumpDepthUnit
      irrigationScheduleType
      minimumSoilMoisture
      irrigationAreaPercentage
    }
    soil {
      userSuppliedSlopeSteepness
      userSuppliedSoilOrganicMatter
      userSuppliedSoilPh
      userSuppliedSoilMoisture
      userSuppliedSoilTexture
    }

    owner {
      firstName
      lastName
    }
    events {
      id
      owner {
        firstName
        lastName
      }
      eventNote
      activityDatetime
      canEdit
      canDelete
      __typename
      ... on Tillage {
        equipment {
          id
          type
          equipmentType
        }
        otherTillageDepthValue
        otherTillageDepthUnit
        tillageAreaPercentage
        residueCoverage
      }

      ... on HailLog {
        probability
        sizeValue
        sizeUnit
        durationValue
        durationUnit
        density
        cropAndGrowthStage
        fieldInspected
        fieldDamaged
        percentDamaged
      }
      ... on PlantingCompletion {
        varieties {
          seed {
            id
            brand
            name
            type
          }
          seedingMethod
          openerType {
            name
            id
          }
          openerWidthValue
          openerWidthUnit
          populationValue
          populationUnit
          rowSpacingValue
          rowSpacingUnit
          depthValue
          depthUnit
          seedTreatmentApplied
          removeSeed
        }
      }
      ... on SoilTest {
        aluminumUnit
        aluminumValue
        ammoniumUnit
        ammoniumValue
        baseSatCaUnit
        baseSatCaValue
        baseSatHUnit
        baseSatHValue
        baseSatKUnit
        baseSatKValue
        baseSatMgUnit
        baseSatMgValue
        boronUnit
        boronValue
        bufferPHUnit
        bufferPHValue
        cadmiumUnit
        cadmiumValue
        calciumUnit
        calciumValue
        cecUnit
        cecValue
        chlorineUnit
        chlorineValue
        cobaltUnit
        cobaltValue
        copperUnit
        copperValue
        isCoverCropRemoval
        isFiftyPercentPhosphorusRemovalRate
        eventNote
        ironUnit
        ironValue
        isntUnit
        isntValue
        leadUnit
        leadValue
        magnesiumUnit
        magnesiumValue
        manganeseUnit
        manganeseValue
        molybdenumUnit
        molybdenumValue
        nickelUnit
        nickelValue
        nitrateUnit
        nitrateValue
        organicMatterUnit
        organicMatterValue
        phosphorusUnit
        phosphorusValue
        pHUnit
        pHValue
        potassiumUnit
        potassiumValue
        ppntUnit
        ppntValue
        psntUnit
        psntValue
        salinityUnit
        salinityValue
        silverUnit
        silverValue
        sampleDepthType
        sodiumUnit
        sodiumValue
        sulfurUnit
        sulfurValue
        totalCopperUnit
        totalCopperValue
        totalIronUnit
        totalIronValue
        totalMagnesiumUnit
        totalMagnesiumValue
        totalManganeseUnit
        totalManganeseValue
        totalNitrogenUnit
        totalNitrogenValue
        totalPhosphorusUnit
        totalPhosphorusValue
        totalPotassiumUnit
        totalPotassiumValue
        totalSulfurUnit
        totalSulfurValue
        zincUnit
        zincValue
      }
      ... on HarvestCompletion {
        editDryingTranspoProfile {
          firstName
          lastName
        }
        totalHarvestedArea
        abandonedAcresReason
        actualYieldValue
        actualYieldUnit
        postHarvestLossValue
        postHarvestLossUnit
        targetYieldValue
        targetYieldUnit
        harvestMoisture
        residueManagement
        proteinPercentage
        sugarPercentage
        userEnteredWetYield
        wetYieldValue
        wetYieldUnit

        dryingSystem
        dryingMethod
        transportationMode
        dryingEnergySource
        dryingFuelType
        finalMoisture
        transportationDate
        distanceToSaleValue
        distanceToSaleUnit
        dryingTranspoEventNote

        canEditYear
      }

      ... on Plan {
        asAppliedEpisodeId
        author {
          name
        }
        community {
          name
          uuid
        }
      }
      ... on Irrigation {
        irrigationAmountValue
        irrigationAmountUnit
        durationOfWateringValue
        durationOfWateringUnit
        startDate
        endDate
        irrigatedAreaValue
        irrigatedAreaUnit
        irrigationScheduleType
        irrigationTotalValue
        irrigationTotalUnit
        irrigationAreaPercentage
      }
      ... on ChemicalApplication {
        applicationAreaPercentage
        applicationMethod {
          templateId
          method
        }
        chemicalMix {
          templateId
          components {
            id
            productId
            productName
            type
            componentRateValue
            componentRateUnit
            liquidChemicalConcentrationValue
            liquidChemicalConcentrationUnit
            componentActiveIngredientPercent
          }
        }
        applicator {
          templateId
          certificationNumber
        }
      }
      ... on FertilizerApplication {
        intendedApplicationDuration
        applicationAreaPercentage
        isVariableRate
        midRowBanding
        pairedRowBanding
        seedplacedBanding
        sidebandBanding
        openerType {
          name
          id
        }
        openerWidthValue
        openerWidthUnit
        applicationMethod {
          templateId
          methodId
          methodName
          depthValue
          depthUnit
          isVariableRate
        }
        fertilizerBlend {
          templateId
          components {
            id
            kind
            type
            productId
            productName
            fertilizerId
            brandId
            brandName
            entryMethod
            intendedApplicationDuration
            userAmountValue
            userAmountUnit
            applicationRateValue
            applicationRateUnit
            stabilizerId
            stabilizerName
          }
        }
        applicator {
          templateId
          certificationNumber
        }
      }
    }
  }
}
`

export const SEASON_DELETE_QUERY = `
mutation DeleteCroppingSeason(
  $fieldId: String!
  $seasonId: Int!
  $profileUuid: String!
) {
  deleteCroppingSeason(
    fieldId: $fieldId
    seasonId: $seasonId
    profileUuid: $profileUuid
  )
}
`

export const SEASONS_DELETE_QUERY = `
mutation DeleteCroppingSeasons(
  $seasons: [FieldSeasonIds!]!
  $profileUuid: String!
) {
  deleteCroppingSeasons(
    seasons: $seasons
    profileUuid: $profileUuid
  )
}
`

export const CREATE_PLAN_MUTATION = `
mutation CreateCarbonPlan(
  $membershipUuid: String!,
  $details: CarbonPlanAndSeason!
) {
  createCarbonPlan(
    membershipUuid: $membershipUuid,
    details: $details
  ){
    id
    plannedReductionAmount
    tillage
    irrigation
    stabilizer
    nutrientManagementPlanImplemented
    croppingEpisodeId
    plans {
      fieldUuid
      planId
      planEpisodeId
    }
  }
}
`

export const GENERAL_PRACTICES_QUERY = `
query GeneralPractices($orgId: String!, $profileUuid: String!, $generalPracticesId: Int, $year: Int){
  getGeneralPractices(orgId: $orgId, profileUuid: $profileUuid, generalPracticesId: $generalPracticesId, year: $year){
    complies_with_farming_regulations
    practices_integrated_pest_management
    reuse_and_recycle_farm_wastes
    has_documented_plan
    tests_manure_and_residues_composition
    has_water_use_plan
    provides_temporary_workers_paid_time_off
    provides_health_and_safety_training
    has_fertilizer_or_fuel_safety_procedures
    performs_regular_maintenance
    participates_in_safe_chemical_container_disposal
    minimizes_noise_or_air_pollution
    has_farm_succession_or_education_plan
    has_crop_loss_financial_safeguards
    id
    is_worker_safety_information_accessible
    implementing_approved_management_plan
    land_ownership_documentation
    seek_training_consultation
    bioengineered_crops_handling
    conservation_areas_preserved
    biodiversity_conservation_practices
    worker_facilities_and_housing_conditions
    worker_association_rights
    ensure_minors_education
    right_source
    right_rate
    right_time
    right_place
    nutrient_application_rate_recommendation_percentage
  }
}
`

export const SAVE_GENERAL_PRACTICES = `
  mutation SaveGeneralPractices($orgId: String!, $profileUuid: String!, $generalPracticesId: Int!, $answers: GeneralPracticesQuestionsInput!) {
    saveGeneralPractices(orgId: $orgId, profileUuid: $profileUuid, generalPracticesId: $generalPracticesId, answers: $answers) {
      complies_with_farming_regulations
      practices_integrated_pest_management
      reuse_and_recycle_farm_wastes
      has_documented_plan
      tests_manure_and_residues_composition
      has_water_use_plan
      provides_temporary_workers_paid_time_off
      provides_health_and_safety_training
      has_fertilizer_or_fuel_safety_procedures
      performs_regular_maintenance
      participates_in_safe_chemical_container_disposal
      minimizes_noise_or_air_pollution
      has_farm_succession_or_education_plan
      has_crop_loss_financial_safeguards
      is_worker_safety_information_accessible
      implementing_approved_management_plan
      right_source
      right_rate
      right_time
      right_place
      nutrient_application_rate_recommendation_percentage,
      year
    }
  }
`
export const CREATE_GENERAL_PRACTICES = `
  mutation CreateGeneralPractices($orgId: String!, $profileUuid: String!, $year: Int!, $answers: GeneralPracticesQuestionsInput!) {
    createGeneralPractices(orgId: $orgId, profileUuid: $profileUuid, year: $year, answers: $answers) {
      complies_with_farming_regulations
      practices_integrated_pest_management
      reuse_and_recycle_farm_wastes
      has_documented_plan
      tests_manure_and_residues_composition
      has_water_use_plan
      provides_temporary_workers_paid_time_off
      provides_health_and_safety_training
      has_fertilizer_or_fuel_safety_procedures
      performs_regular_maintenance
      participates_in_safe_chemical_container_disposal
      minimizes_noise_or_air_pollution
      has_farm_succession_or_education_plan
      has_crop_loss_financial_safeguards
      is_worker_safety_information_accessible
      implementing_approved_management_plan
      right_source
      right_rate
      right_time
      right_place
      nutrient_application_rate_recommendation_percentage
    }
  }
`

export const ORG_LEVEL_QUESTIONS_QUERY = `
  query OrgLevelQuestions($orgId: String!, $profileUuid: String!, $generalPracticesId: Int, $year: Int){
    getOrgLevelQuestions(orgId: $orgId, profileUuid: $profileUuid, generalPracticesId: $generalPracticesId, year: $year){
      previouslyAnswered
      id
      answers {
        Q1
        Q2
        Q3
        Q4
        Q5
        Q6
        Q7
        Q8
        Q9
        Q10
        Q11
        Q12
        Q13
        Q14
        Q15
        Q16
        Q17
        Q18
        Q19
        Q20
        Q21
        Q22
        Q23
        Q24
        Q25 {
          Q1
          Q2
          Q3
          Q4
        }
        Q26
      }
    }
  }
`

export const SAVE_ORG_LEVEL_QUESTIONS = `
  mutation SaveOrgLevelQuestions($orgId: String!, $profileUuid: String!, $generalPracticesId: Int!, $answers: QuestionAnswersInput!){
    saveOrgLevelQuestions(orgId: $orgId, profileUuid: $profileUuid, generalPracticesId: $generalPracticesId, answers: $answers){
        previouslyAnswered
    }
  }
`

export const ELIGIBLE_SEASONS_QUERY = `
query GetCarbonPlanEligibleSeasons(
  $fieldId: String!,
  $membershipUuid: String!,
  $unit: String
) {
  getCarbonPlanEligibleSeasons(
    fieldId: $fieldId,
    membershipUuid: $membershipUuid,
    unit: $unit
  ){
    id
    crop {
      id
      name
      isCoverCrop
      isFsSupported
      isSustainabilitySupportedCrop
      isSilage
    }
    specificCrop {
      id
      name
    }
    startDate
    endDate
    owner {
      firstName
      lastName
    }
    events {
      id
      owner {
        firstName
        lastName
      }
      eventNote
      activityDatetime
      canEdit
      canDelete
      __typename
    }
  }
}
`
export const EDIT_CROPPING_SEASON = `
mutation EditCroppingSeason(
  $fieldId: String!,
  $seasonId: Int!,
  $profileUuid: String!,
  $details: CroppingSeasonEdit
) {
  editCroppingSeason(
    fieldId: $fieldId,
    seasonId: $seasonId,
    profileUuid: $profileUuid,
    details: $details
  ){
    id
  }
}
`

export const PLAN_QUERY = `
query GetCarbonPlan($membershipUuid: String!, $planId: Int, $unit: String) {
  getCarbonPlan (membershipUuid: $membershipUuid, planId: $planId, unit: $unit){
    croppingEpisodeId
    plannedReductionAmount
    tillage
    stabilizer
    irrigation
    nutrientManagementPlanImplemented
    completed4RNitrogenPlanPrevYear
    rateDeterminedAtFarmField
    consultedSoilSamplePrevYear
    includedNutrientCreditPrevCrop
    varyFertilizerRateLandscapeSoil
    nBalanceCalculatedPrevYear
    nitrogenRateAdjustedEefUsed
    avgNitrogenUnit
    avgNitrogenValue
    isVariableNitrogenApplied
    isEefApplied
    isFallFertilizerApplied
    isFallAnhydrousApplied
    isFallUanApplied
    isFallManureApplied
    isUsdaWarrantyOfferingInterested
    id
    lovelandProductsIncentive {
      incentive
      products {
        id
        name
        isSelected
      }
    }
  }
}
`

export const GET_FIELD_ROTATIONS = `
    query getFieldRotations($fieldId: String!, $profileId: String!){
      getFieldRotations(fieldId: $fieldId, profileId: $profileId){
        rotation{
          id
          seasons{
            harvestDate
            plantingDate
            cropKind{
              id
              name
            }
            specificCropKind{
              id
              name
            }
            startDate
            endDate
            isFallow
            isCoverCrop
            isSelected
          }
        }
        eligibleRotations{
          id
          rotationName
        }
      }
    }
`

export const GET_HISTORICAL_SEASONS_FOR_ROTATION = `
    query getHistoricalSeasonsForRotation($orgId: String!, $rotationId: Int!, $userId: String!){
      getHistoricalSeasonsForRotation(orgId: $orgId, rotationId: $rotationId, userId: $userId){
          id
          seasons{
            harvestDate
            plantingDate
            startDate
            endDate
            isCoverCrop
            isFallow
            cropKind{
              id
              name
            }
            specificCropKind{
              id
              name
            }
        }
      }
    }
`

/* note: one of the seasons provided must be of the same type as a field's plan season or the field will not be eligible */
export const CREATE_ROTATION_MUTATION = `
mutation createRotation($orgId: String!, $seasons: [CropRotationSeasonInput!]!, $userId: String!){
  createRotation(orgId: $orgId, seasons: $seasons, userId: $userId){
    id
    rotationName
    seasons{
      harvestDate
      plantingDate
      startDate
      endDate
      seasonName
      isFallow
      isCoverCrop
      cropKind{
        id
        name
      }
      specificCropKind{
        id
        name
      }
    }
  }
}
`

export const UPDATE_ROTATION_MUTATION = `
mutation updateRotation(
  $orgId: String!,
  $rotationId: Int!,
  $seasons: [CropRotationSeasonInput!]!,
  $userId: String!
) {
  updateRotation(
    orgId: $orgId,
    rotationId: $rotationId,
    seasons: $seasons,
    userId: $userId
  ) {
    id
    rotationName
    seasons{
      harvestDate
      plantingDate
      seasonName
      isCoverCrop
      startDate
      endDate
      isFallow
      cropKind{
        id
        name
      }
      specificCropKind{
        id
        name
      }
    }
  }
}
`

export const UPDATE_FIELDS_ROTATION_MUTATION = `
mutation updateRotationFields(
  $membershipId: String!
  $rotationId: Int!
  $payload: FieldsInRotationInput!
) {
  updateRotationFields(
    membershipId: $membershipId
    rotationId: $rotationId
    payload: $payload
  ) {
    assignedFields {
      uuid
    }
    eligibleFields {
      uuid
    }
  }
}`

export const SUBMIT_PLANS_QUERY = `
mutation SubmitPlan($membershipUuid: String!) {
  submitCarbonPlan(membershipUuid: $membershipUuid)
}
`
export const SUBMIT_BASELINE_QUERY = `
mutation SubmitBaselinePlan($membershipUuid: String!) {
  submitBaselinePlan(membershipUuid: $membershipUuid)
}`

export const SUBMIT_FIELD_ACTIVITY = `
mutation SubmitFieldActivity($membershipUuid: String!, $episodeIds: [Int], $status: String) {
  submitFieldActivity(membershipUuid: $membershipUuid, episodeIds: $episodeIds, status: $status)
}
`
export const EXCLUDE_SEASONS_FROM_PROJECT_FLOW = `
mutation ExcludeSeasonsFromProjectFlow($campaignUuid: String!, $episodeIds: [Int]!, $note: String) {
  excludeSeasonsFromProjectFlow(campaignUuid: $campaignUuid, episodeIds: $episodeIds, note: $note)
}
`

export const BASELINE_SEASONS_QUERY = `
query GetBaselineSeasons($membershipId: String!, $rotationId: Int) {
  getBaselineSeasons (membershipId: $membershipId, rotationId: $rotationId){
    seasons {
      seasonName
      seasonFieldUuids
      rotationSeasonId
      isBaselineRequired
      isTargetSeason
      isRotationSeason
      crop
      isCoverCrop
      specificCrop {
        id
      }
      events {
        eventName
        status
        completedCount
        actionRequiredTxt
      }
    }
    totalFields
  }
}
`

export const BASELINE_FERT_EVENT_MUTATION = `
mutation SaveBaselineFertEvent($membershipId: String!, $rotationId: Int, $seasonId: String, $fields: [String], $details: FertilizerApplicationBaselineCreate! ) {
  saveBaselineFertEvent(membershipId: $membershipId, rotationId: $rotationId, seasonId: $seasonId, fields: $fields, details: $details)
}
`

export const BASELINE_HARVEST_EVENT_MUTATION = `
mutation SaveBaselineHarvestEvent($profileUuid: String, $membershipId: String!, $campaignType: String, $rotationId: Int, $seasonId: String, $details: BaselineHarvestDetails!, $singleStep: Boolean) {
  saveBaselineHarvestEvent(profileUuid: $profileUuid, membershipId: $membershipId, campaignType: $campaignType, rotationId: $rotationId, seasonId: $seasonId, details: $details, singleStep: $singleStep){
    fieldCount
    successCount
    details {
      fieldUuid,
      episodeId,
      info,
      activitySaved
    }
  }
}
`

export const BASELINE_BEST_PRACTICES = `
mutation SaveBaselineBestPractices($membershipId: String!, $rotationId: Int, $seasonId: String, $details: BaselineBestPractices!) {
  saveBaselineBestPractices(membershipId: $membershipId, rotationId: $rotationId, seasonId: $seasonId, details: $details)
}
`

export const BASELINE_TILLAGE_EVENT_MUTATION = `
mutation SaveBaselineTillageEvent($membershipId: String!, $rotationId: Int, $seasonId: String, $fields: [String], $details: TillageApplicationBaselineCreate! ) {
  saveBaselineTillageEvent(membershipId: $membershipId, rotationId: $rotationId, seasonId: $seasonId, fields: $fields, details: $details)
}
`

export const FERTILIZER_HARVEST_CHOICES_QUERY = `
query listFieldStoryMetas($unit: Units!) {
  listFieldStoryMetas(unit: $unit) {
    harvestCompletionUnits {
      actualYield
      targetYield
      distanceToSale
    }
    fertilizerApplicationUnits {
      applicationDepth
      mass
      volume
    }
    fertilizerApplicationChoices {
      applicationMethod {
        id
        name
        depths
      }
      stabilizer {
        id
        name
      }
    }
  }
}
`
export const NO_FERTILIZER_EVENT = `
mutation AcknowledgeNoFertilizerEvent(
  $membershipId: String!
  $fields: [String]
  $rotationId: Int!
  $seasonId: String!
) {
  acknowledgeNoFertilizerEvent(
    membershipId: $membershipId
    fields: $fields
    rotationId: $rotationId
    seasonId: $seasonId
  )
}
`

export const NO_TILLAGE_EVENT = `
mutation AcknowledgeNoTillageEvent(
  $membershipId: String!
  $fields: [String]
  $rotationId: Int!
  $seasonId: String!
) {
  acknowledgeNoTillageEvent(
    membershipId: $membershipId
    fields: $fields
    rotationId: $rotationId
    seasonId: $seasonId
  )
}
`

export const GET_CROPPING_SEASONS_FOR_FIELD_WITH_FILTER_FLAGS = `
  query GetCroppingSeasonsForFieldWithFilterFlags($membershipId: String!, $fieldId: String!, $logFieldActivity: Boolean, $measurementCampaign: Boolean) {
    getCroppingSeasonsForFieldWithFilterFlags(membershipId: $membershipId, fieldId: $fieldId, logFieldActivity: $logFieldActivity, measurementCampaign: $measurementCampaign) {
      seasonName
      crop
      specificCrop
      rotationSeasonId
      croppingEpisodeId
      isTargetSeason
      isFallow
      isRotationSeason
      isCoverCrop
      isExcluded
      noFertilizerApplicationsAcknowledged
      isBaselineRequired
      canEdit
      canDelete
      events {
        eventType
        date
        id
        planEpisodeId
        status
        author
      }
    }
  }
`

export const GET_STEPPER_CROPPING_SEASONS = `
  query getStepperCroppingSeasons($membershipId: String!, $fieldId: String!, $projectStep: String!) {
    getStepperCroppingSeasons(membershipId: $membershipId, fieldId: $fieldId, projectStep: $projectStep) {
      seasonName
      crop
      specificCrop
      rotationSeasonId
      croppingEpisodeId
      isTargetSeason
      isRotationSeason
      isBaselineRequired
      canAddPlan
      canEdit
      isExcluded
      canDelete
      isCoverCrop
      isFallow
      noBestPracticesAcknowledged
      noFertilizerApplicationsAcknowledged
      noTillageAcknowledged
      events {
        eventType
        date
        id
        status
        author {
          uuid
          name
        }
        evidence {
          isRequired
          requiresGeoTimestamp
          evidenceType
          alternativeTo
        }
        ... on StepperPlanEvent {
          campaign {
            uuid
            name
          }
          membership {
            uuid
          }
          isSubmitted
          planEpisodeId
        }
      }
    }
  }
`

export const FIELD_CHARACTERISTICS_UPDATE_QUERY = `
mutation editCroppingSeason(
  $fieldId: String!
  $seasonId: Int!
  $profileUuid: String!
  $details: CroppingSeasonEdit!
  $acknowledgeNoEvent: Boolean
) {
  editCroppingSeason(
    fieldId: $fieldId
    seasonId: $seasonId
    profileUuid: $profileUuid
    details: $details
    acknowledgeNoEvent: $acknowledgeNoEvent
  ) {
    id
  }
}
`

export const EDIT_CROPPING_SEASONS = `
  mutation EditCroppingSeasons(
    $profileUuid: String!
    $detailsArr: [CroppingSeasonEdit]!
  ) {
    editCroppingSeasons(
      profileUuid: $profileUuid
      detailsArr: $detailsArr
    )
  }
`

export const ADD_CROPPING_SEASON_MUTATION = `
mutation AddCroppingSeason(
  $fieldId: [String!]!
  $details: CroppingSeasonCreate!
  $profileUuid: String!
) {
  addCroppingSeason(
    fieldId: $fieldId
    profileUuid: $profileUuid
    details: $details
  ) {
    id
  }
}`

export const EVENT_DELETE_QUERY = `
mutation editCroppingSeason(
  $fieldId: String!
  $profileUuid: String!
  $seasonId: Int!
  $eventId: Int!
  $eventType: SeasonEventType!
) {
  deleteCroppingSeasonEvent(
    fieldId: $fieldId
    profileUuid: $profileUuid
    seasonId: $seasonId
    eventId: $eventId
    eventType: $eventType
  )
}
`

export const GET_CAMPAIGNS_ENROLLMENT_NAVIGATION_TREE = `
  query getEnrolledFieldsForCampaign($membershipUuid: String!) {
    getEnrolledFieldsForCampaign(membershipUuid: $membershipUuid) {
      uuid
      isBuildCropRotationsComplete
      isDocumentBaselineComplete
      isLogFieldActivityComplete
      isFieldActivityMarkedComplete
      rotationId
    }
  }
`

export const GET_INVALID_DATES_FOR_FIELD = `
query getInvalidDatesForFieldPlantingAndHarvesting($fieldId: String!, $profileUuid: String!){
  getInvalidDatesForFieldPlantingAndHarvesting(fieldId: $fieldId, profileUuid: $profileUuid) {
    start
    end
  }
}
`

export const REMOVE_FIELD_FROM_CAMPAIGN = `
  mutation removeFieldFromCampaign($membershipId: String!, $fieldId: String!){
    removeFieldFromCampaign(membershipId: $membershipId, fieldId: $fieldId)
  }
`

export const GET_CAMPAIGN_CROPS = `
  query GetCarbonPlanEligibleCrops($membershipId: String!, $fieldId: String!){
    getCarbonPlanEligibleCrops(membershipId: $membershipId, fieldId: $fieldId){
      id
      name
      specificCrops{
        id
        name
      }
    }
  }
`

export const SUBMIT_CREATE_PLAN = `
  mutation createCarbonPlan($membershipUuid: String!, $details: CarbonPlanAndSeason!){
    createCarbonPlan(membershipUuid: $membershipUuid, details: $details){
      id
      croppingEpisodeId
      plans {
        fieldUuid
        planId
        planEpisodeId
      }
    }
  }
`

export const GET_ELIGIBLE_FIELDS_FOR_COPY = `
  query getEligibleFieldForCopy($membershipId: String!, $payload: SelectFieldPayload!) {
    getEligibleFieldForCopy(
      membershipId: $membershipId
      payload: $payload
    )
  }
`
/**
 * getEvidenceRequirements request is currently configure to request evidence for
 * each field event grouped by either baselineSeasonRequirements or
 * targetSeasonRequirements.
 */
export const FETCH_EVIDENCE_REQUIREMENTS = `
fragment evidence on EvidenceReqs {
  isRequired
  evidenceType
  alternativeTo
  requiresGeoTimestamp
}
query GetEvidenceRequirements($campaignId: String!) {
  getEvidenceRequirements(campaignId: $campaignId) {
    targetSeasonRequirements {
      plan { ...evidence }
      harvest { ...evidence }
      irrigation { ...evidence }
      fertilizer { ...evidence }
      tillage { ...evidence }
      cropProtection { ...evidence }
      generalPractices { ...evidence }
      soilTest { ...evidence }
      fieldCharacteristics { ...evidence }
      hailLog { ...evidence }
    }
  }
}`

export const ASSOCIATE_EVIDENCE = `
mutation associateEvidence($eventRecordsInput: [EventRecordsInput]!, $profileUuid: String) {
  associateEvidence(
    eventRecordsInput: $eventRecordsInput,
    profileUuid: $profileUuid
  )
}
`

export const GET_SEED_TREATMENT_OPTIONS = `
  query GetSeedTreatmentOptions($cropId: String!) {
    getSeedTreatmentOptions(cropId: $cropId) {
      name
    }
  }
`

/*
This is needed until the BE is changed. It expects this casing
  label: value
  value: name
*/
export const GET_TIME_OF_COMPLETION_CHOICES = `
  query GetTimeOfCompletionChoices($membershipId: String!, $fieldId: String!, $rotationSeasonId: String!, $activityType: String!) {
    getTimeOfCompletionChoices(membershipId: $membershipId, fieldId: $fieldId, rotationSeasonId: $rotationSeasonId, activityType: $activityType) {
      episodeId,
      timeOfCompletion{
        label: value
        value: name
      }
    }
  }
  `

export const GET_PROJECT_STEPS = `
  query getProjectSteps($membershipId: String!) {
    getProjectSteps (membershipId: $membershipId) {
      projectSteps {
        id
        step
        isCompleted
        seasonsCompletedArea
      }
      fieldsTotalCount
      fieldsTotalArea
      targetSeasonsTotalArea
      planSeasonsCount
      areaUnits
      fieldsErrors {
        uuid
        seasons {
          missingTargetCropSeasons
          missingRequiredPreviousSeasons
          specificCrop {
            name
          }
        }
      }
    }
  }
`

export const FETCH_CAMPAIGN_INCENTIVES = `
query getCarbonPlanIncentives($campaignId: String!) {
  getCarbonPlanIncentives(campaignId: $campaignId) {
    incentives {
      incentive
      products{
        id
        name
      }
    }
  }
}`

export const GET_ROTATION_SEASON_COPY_VALID_FIELDS = `
  query GetUpdateRotationSeasonValidFields($membershipId: String!, $fieldId: String!, $rotationSeasonId: String!, $details: UpdateRotationSeason!) {
    getUpdateRotationSeasonValidFields(membershipId: $membershipId, fieldId: $fieldId, rotationSeasonId: $rotationSeasonId, details: $details) {
      uuid
      name
    }
  }
`

export const UPDATE_ROTATION_SEASON = `
  mutation UpdateRotationSeason($membershipId: String!, $fieldId: String!, $rotationSeasonId: String!, $details: UpdateRotationSeason!, $copyFields: [String]) {
    updateRotationSeason(membershipId: $membershipId, fieldId: $fieldId, rotationSeasonId: $rotationSeasonId, details: $details, copyFields: $copyFields) {
      croppingEpisodeId
      rotationSeasonId
    }
  }
`

export const CROP_QUERY = `
  query listCrops(
    $agribleSupportedCrop: Boolean
    $agribleSupportedSpecificCrop: Boolean
  ) {
    listCrops(
      agribleSupportedCrop: $agribleSupportedCrop
      agribleSupportedSpecificCrop: $agribleSupportedSpecificCrop
    ) {
      id
      name
      isFsSupported
      isCoverCrop
      isSustainabilitySupportedCrop
      isOtherCrop
      isSilage
      specificCrops {
        id
        name
      }
    }
}
`

export const LIST_ROTATION_SEASON_FOR_EVENT_CREATION = `
  query ListRotationSeasonForEventCreation($membershipId: String!, $fieldId: String!, $rotationSeasonId: String!, $eventType: String!, $preSelect: Boolean, $details: BaselineEventEdit!) {
    listRotationSeasonForEventCreation(membershipId: $membershipId, fieldId: $fieldId, rotationSeasonId: $rotationSeasonId, eventType: $eventType, preSelect: $preSelect, details: $details) {
      validFields
      preSelectedFields
    }
  }
`

export const GET_BEST_PRACTICES = `
  query GetBestPractices($membershipId: String, $fieldId: String!, $episodeId: Int, $profileUuid: String) {
    getBestPractices (membershipId: $membershipId, fieldId: $fieldId, episodeId: $episodeId, profileUuid: $profileUuid) {
      noBestPracticesAcknowledged
      bestPractices {
        id
        name
        selected
        required
      }
    }
  }
`

export const SAVE_BEST_PRACTICES = `
  mutation SaveBestPractices($membershipId: String, $fieldId: String!, $episodeId: Int!, $profileUuid: String, $details: BestPracticesDetailsInput) {
    saveBestPractices (membershipId: $membershipId, fieldId: $fieldId, episodeId: $episodeId, profileUuid: $profileUuid, details: $details) {
      bestPractices {
        id
      }
    }
  }
`

export const GET_BEST_PRACTICES_VALID_FIELDS = `
  query GetBestPracticesValidFields($membershipId: String, $fieldId: String!, $episodeId: Int!,$profileUuid: String) {
    getBestPracticesValidFields (membershipId: $membershipId, fieldId: $fieldId, episodeId: $episodeId, profileUuid: $profileUuid) {
      validFields {
        fieldId
        seasonId
      }
    }
  }
`

export const GET_BOOK_DATA = `
query getBookData($profileUuid: String!, $fieldId: ID!, $cropId: Int!, $year: Int) {
  getBookData(
    profileUuid: $profileUuid,
    fieldId: $fieldId
    cropId: $cropId,
    year: $year
  ) {
    bestHistoricYield {
      value
      unit
    }
    bestHistoricFertilizer {
      value
      unit
    }
  }
}
`

export const GET_BOOK_DATA_LIST = `
query getBookDataList($profileUuid: String!, $fieldIds: [ID!]!, $cropId: Int!, $year: Int) {
  getBookDataList(
    profileUuid: $profileUuid,
    fieldIds: $fieldIds
    cropId: $cropId,
    year: $year
  ) {
    bestHistoricYield {
      value
      unit
    }
    bestHistoricFertilizer {
      value
      unit
    }
  }
}
`

export const GET_MARK_AS_CUSTOM_ELIGIBLE_FIELDS = `
  query GetMarkAsCustomEligibleFields($membershipId: String!, $fieldId: String!,  $seasonDetailsArray: [CropRotationSeasonInput]) {
    getMarkAsCustomEligibleFields(membershipId: $membershipId, fieldId: $fieldId, seasonDetailsArray: $seasonDetailsArray)
  }
`
export const MARK_AS_CUSTOM_MUTATION = `
  mutation MarkAsCustom($membershipId: String!, $rotationId: Int!, $fieldId: String!, $copyFields: [String],  $seasonDetailsArray: [CropRotationSeasonInput]) {
    markAsCustom(membershipId: $membershipId, rotationId: $rotationId,  fieldId: $fieldId, copyFields: $copyFields, seasonDetailsArray: $seasonDetailsArray)
  }
`

export const GET_COPY_SEASONS_ELIGIBLE_FIELDS = `
  query GetCopySeasonsEligibleFields($membershipId: String!, $fieldId: String!) {
    getCopySeasonsEligibleFields(membershipId: $membershipId, fieldId: $fieldId)
  }
`
export const COPY_BASELINE_SEASONS_MUTATION = `
  mutation CopyBaselineSeasons($membershipId: String!, $rotationId: Int!, $fieldId: String!, $copyFields: [String]!) {
    copyBaselineSeasons(membershipId: $membershipId, rotationId: $rotationId,  fieldId: $fieldId, copyFields: $copyFields)
  }
`

export const RESET_FIELD_ROTATION_MUTATION = `
  mutation ResetFieldRotation($membershipId: String!, $rotationId: Int!, $fieldId: String!) {
    resetFieldRotation(membershipId: $membershipId, rotationId: $rotationId, fieldId: $fieldId)
  }
`
export const ADD_FIELDS_TO_ROTATION_MUTATION = `
  mutation AddFieldsToRotation($membershipId: String!, $rotationId: Int!, $fields: [String]!) {
    addFieldsToRotation(membershipId: $membershipId, rotationId: $rotationId, fields: $fields) {
      assignedFields {
        uuid
      }
      eligibleFields {
        uuid
      }
    }
}`

export const REFETCH_STEPPER_FARM_TREE_QUERY = `
  query RefetchStepperFarmTree($userId: String!, $campaignId: String!, $membershipUuid: String!) {
    getEnrolledFieldsNavigationTree(userId: $userId, campaignId: $campaignId, membershipUuid: $membershipUuid) {
      farmTree {
        uuid
        name
        type
        parentUUID
        canDelete
        permissions
        ... on Container {
          owner {
            uuid
            name
          }
          childrenUUIDs
        }
        ... on Field {
          acreage
          canDelete
          poly
          extent
          centroid { type coordinates }
          rotationId
          enrolledAcres
          isCustom
          buildCropRotationStatusIncomplete
          baselineStatusIncomplete
          logFieldDataStatusIncomplete
          fieldActivityMarkedComplete
          isExcluded
          isCustom
          campaignEnrollment {
            locked
            campaignId
            enrolledStatus
            carbonPlanId
            hasLandRights
            hasSustainabilityPlan
            isExcluded
            carbonPlanIds
            episodes {
              canCreatePlan
              canDelete
              canEdit
              isPlan
              isTargetSeason
              plan {
                campaign {
                  name
                  uuid
                }
                canDelete
                canEdit
                id
              }
            }
          }
        }
      }
      allFarms {
        uuid
        parentUUID
        name
      }
    }
  }`

export const SEEDING_METHOD_QUERY = `
query SeedingMethod($specificCropId: String!) {
  listSeedingMethod(specificCropId: $specificCropId) {
    id
    name
  }
}
`
export const GET_ROTATION_ELIGIBLE_FIELDS = `
  query getRotationEligibleFields($membershipId: String!, $rotationId: Int!, $fieldId: String)  {
    getRotationEligibleFields(
      membershipId: $membershipId
      rotationId: $rotationId,
      fieldId: $fieldId
    ) {
      selectedFields
      allFields
    }
  }
`

export const DELETE_PLAN = `
mutation DeletePlan($membershipUuid: String!, $planId: Int!) {
  deletePlan (membershipUuid: $membershipUuid, planId: $planId)
}
`

export const EXCLUDE_SEASONS_FROM_CAMPAIGN = `
mutation ExcludeSeasonsFromCampaign($membershipId: String, $seasons: [ExcludeSeasonInput]) {
  excludeSeasonsFromCampaign(membershipId: $membershipId, seasons: $seasons)
}
`

export const GET_MEMBERSHIP_QUERY = `
query GetMembership($profileUuid: String!, $campaignUuid: String!, $status: String, $permission: String, $summary: Boolean){
  getMembership(profileUuid: $profileUuid, campaignUuid:$campaignUuid, status:$status, permission:$permission, summary: $summary){
    uuid
    status
    parent {
      uuid
      kind
      name
    }
    region {
      uuid
      kind
      name
    }
  }
}
`

export const LIST_VALID_FIELDS_FOR_CREATE_SEASON = `
  query ListValidFieldsForSeasonCreation(
    $fieldId: String!
    $details: CroppingSeasonCreate!
    $profileUuid: String!
  ) {
    listFieldsForSeasonCreation(
      fieldId: $fieldId
      details: $details
      profileUuid: $profileUuid
    ) {
      validFields
    }
  }
`
export const LIST_VALID_FIELD_FOR_SEASON_COPY = `
  query ListFieldsForSeasonCopy(
    $fieldId: String!
    $seasonId: Int!
    $campaignId: String
    $profileUuid: String!
  ) {
    listFieldsForSeasonCopy(
      fieldId: $fieldId
      seasonId: $seasonId
      campaignId: $campaignId
      profileUuid: $profileUuid
    ) {
      validFields
    }
  }
`

export const LIST_VALID_FIELDS_FOR_CREATE_EVENT = `
  query ListCroppingSeasonForEventCreation(
    $profileUuid: String!
    $fieldId: String!
    $seasonId: Int!
    $details: EventEdit!
    $campaignId: String
  ) {
    listCroppingSeasonForEventCreation(
      profileUuid: $profileUuid
      fieldId: $fieldId
      seasonId: $seasonId
      details: $details
      campaignId: $campaignId
    ) {
      validFields {
        fieldId
        seasonId
      }
      duplicatedFields {
        fieldId
        seasonId
      }
      overriddenFields {
        fieldId
        seasonId
      }
    }
  }
`
export const LIST_VALID_FIELDS_FOR_COPY_EVENT = `
  query ListCroppingSeasonForEventCopy(
    $fieldId: String!
    $profileUuid: String!
    $seasonId: Int!
    $eventId: Int!
    $eventType: SeasonEventType!
    $campaignId: String
  ) {
    listCroppingSeasonForEventCopy(
      fieldId: $fieldId
      profileUuid: $profileUuid
      seasonId: $seasonId
      eventId: $eventId
      eventType: $eventType
      campaignId: $campaignId
    ) {
      validFields {
        fieldId
        seasonId
      }
      duplicatedFields {
        fieldId
        seasonId
      }
      overriddenFields {
        fieldId
        seasonId
      }
    }
  }
`

export const COPY_SEASON_QUERY = `
mutation copyCroppingSeason(
  $fieldId: [String!]!
  $seasonId: Int!
  $profileUuid: String!
) {
  copyCroppingSeason(
    fieldId: $fieldId
    seasonId: $seasonId
    profileUuid: $profileUuid
  )
}`

export const ORGANIZATION_QUERY = `query FetchOrganizationInfo($uuid: String!) {
  getCommunityNode (kind: Organization, uuid: $uuid) {
      uuid
      name
      ... on Organization {
          canDelete
          address {
              addressLine1
              addressLine2
              city
              state
              zipCode
              country
              phoneNumber
          }
          termsAndConditions {
              uuid
              version
              text
              modified
          }
      }
  }
}`

export const GET_TERMS_AND_CONDITIONS = `query GetTermsAndConditions($uuid: String!) {
  getTermsAndConditions(uuid: $uuid) {
    text
  }
}`

export const MEMBER_PROFILE_INFO_QUERY = `query FetchMemberProfileInfo($uuid: String!) {
  getProfileInfo (uuid: $uuid) {
    isSuperUser
}
}`
