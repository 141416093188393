/*
Some of date-fns functions operate on local dates, while other operate on UTC dates
Fix will likely arrive in 2.x https://github.com/date-fns/date-fns/issues/376
Until then, this file is necessary

Dates come in various shapes and forms:
[object Date], Invalid Date, ISOString, YMDString, OtherString, null, undefined
- [object Date] is unreliable, as it's hard to tell what date it currently represents
  Its toISOString and getTime return UTC, whereas its other accessors, like
  toString (`console.log(date)`), getHours, or getFullYear return localized
  This means each [object Date] is two dates at the same time, which is harder to reason about
  and causes nasty bugs. Create short-lived [object Date] only when absolutely necessary
- Invalid Date should not be passed around, keep it short-lived and contained as much as possible
- ISOString is the preferred shape to pass around. It's the most reliable - you get what you read
- YMDString is sometimes used by DOS. It is safe to use it because it behaves the same as ISOString
- However, there are OtherStrings, some of which may look like YMDString (e.g. use / instead of -)
  Those strings are not safe as they are either unpredictable or not defaulting to UTC
  For more info see:
  https://github.com/Agrium/agrible-platform/blob/master/packages/agrible-frontend/src/helpers/date.proofs.iso-vs-ymd.md
  Note: we are assuming that DOS will never send us OtherStrings, only ISO/YMD/null/undefined
- null should be avoided at all times, as `new Date(null)` results in a 1970 valid [object Date]
- undefined is fine, but some invocations may need prepending `variable && mutator(variable)`
In summary, prefer operating on those:
ISOString, YMDString, undefined
And avoid operating on those:
[object Date], Invalid Date, OtherString, null
*/

import dfnsIsDate from 'date-fns/isDate'
import dfnsSubYears from 'date-fns/subYears'
import dfnsStartOfDay from 'date-fns/startOfDay'
import dfnsCompareAsc from 'date-fns/compareAsc'
import dfnsCompareDesc from 'date-fns/compareDesc'
import dfnsIsAfter from 'date-fns/isAfter'
import dfnsIsBefore from 'date-fns/isBefore'
import dfnsIsSameDay from 'date-fns/isSameDay'
import dfnsIsSameYear from 'date-fns/isSameYear'
import dfnsIsValid from 'date-fns/isValid'
import dfnsEndOfDay from 'date-fns/endOfDay'
import dfnsSubDays from 'date-fns/subDays'
import dfnsAddDays from 'date-fns/addDays'
import dfnsParse from 'date-fns/parse'
import dfnsFormat from 'date-fns/format'
import dfnsStartOfWeek from 'date-fns/startOfWeek'
import dfnsEndOfWeek from 'date-fns/endOfWeek'
import dfnsAddWeeks from 'date-fns/addWeeks'
import dfnsIsWithinInterval from 'date-fns/isWithinInterval'
import dfnsSubMonths from 'date-fns/subMonths'
import dfnsDifferenceInDays from 'date-fns/differenceInDays'
import dfnsAddHours from 'date-fns/addHours'
import dfnsIsToday from 'date-fns/isToday'
import dfnsIsTomorrow from 'date-fns/isTomorrow'
import dfnsStartOfHour from 'date-fns/startOfHour'
import dfnsIsYesterday from 'date-fns/isYesterday'
import dfnsGetYear from 'date-fns/getYear'

/*
Takes a localized [object Date] and stringifies it so that it appears
as a UTC ISOstring. This is not the same as calling toISOString.
The result is as-if localized toString was forced into ISO format
This is useful for when we want to take the user's choice and preserve
it as-is on DOS. Or when we want to use the local value of today or
now in comparisons with other UTC dates from DOS. Such UTC ISOString
can then be further fed into other functions exported in this file or
any other utcified mutator/comparator. Once a value was cast to UTC,
it should not be recast again as that will cause a time shift
In other words, use this function only once - when creating new date
If needed, such cast date can also be used in comparisons by instantiating
it to a new Date(). The getTime and toISOString of such date will be in UTC
It is recommended that such date variable is short-lived and used
sparingly. ISOStrings should dominate the data structures instead
*/
export const castToUTC = date => {
  if (!date) {
    return undefined
  }

  if (isISOString(date)) {
    return date
  }

  if (isYMDString(date)) {
    return normalizeYMDString(date)
  }

  /*
  We want to take the date user sees as-is, without conversions
  And cast it into an ISOString, which DOS will assume to be UTC
  But the toISOString function applies offset and converts to UTC
  And even date-fns-tz doesn't help here
  So we need to handle that manually before stringifying
  For more info see:
  https://github.com/Agrium/agrible-platform/blob/master/packages/agrible-frontend/src/helpers/date.proofs.date-fns-vs-timezone-offset.md
  */
  const tzoffset = date.getTimezoneOffset() * 60000
  const forcedUTCDate = new Date(date - tzoffset)
  return isValid(forcedUTCDate) ? forcedUTCDate.toISOString() : undefined
}

// The min/max for js dates is -8640000000000000/8640000000000000
// However, when fed into window.Intl.DateTimeFormat().format(new Date(amount))
// and on IE11, the min/max is -9999999999999/99999999999999
// Extreme values cannot be offset as that results in Invalid Date
export const MIN_DATE = new Date(-9999999999999).toISOString()
export const MAX_DATE = new Date(99999999999999).toISOString()

/*
Reverse of castToUTC - takes an ISO/YMD date string and converts it to
a localized [object Date]. Resulting object's toString will print the same
contents as the ISO/YMD string that was fed into it. Note that object's
toISOString and getTime will be different (shifted to UTC) - avoid using that
This util is useful when we want to use date-fns - which operates mostly on localized
For example startOfDay util ensures the hour is 00:00 on toString, not toISOString
*/
export const castToLocal = dateString => {
  if (dateString === MIN_DATE || dateString === MAX_DATE) {
    return new Date(dateString)
  }

  const isoString = isYMDString(dateString)
    ? normalizeYMDString(dateString)
    : dateString
  if (!isISOString(isoString)) {
    console.error(`Expected UTC ISOString/YMDString, received ${dateString}`)
  }
  /*
  Warning: avoid `(new Date()).getTimezoneOffset()`, i.e. not providing the
  isoString param - this is unreliable in some timezones (Azores Summer Time,
  roughly same as UTC) and causes bugs
  */
  const rawDateObj = new Date(isoString)
  const tzoffset = rawDateObj.getTimezoneOffset() * 60000
  const forcedLocalDate = new Date(rawDateObj.getTime() + tzoffset)
  return isValid(forcedLocalDate) ? forcedLocalDate : undefined
}

// Some DOS endpoints are expecting YMD instead of ISO
export const toYMD = dateString => {
  if (isYMDString(dateString)) {
    return dateString
  }
  if (!isISOString(dateString)) {
    console.error(`Expected UTC ISOString, got ${dateString}`)
  }
  return format(dateString, 'yyyy-MM-dd')
}

export const throwOnDateObj = arg => {
  // This will also catch Invalid Date
  if (dfnsIsDate(arg))
    console.error('Expected UTC ISOString, received date object instead')
}

export const isISOString = arg =>
  !!(
    typeof arg === 'string' &&
    arg.match(/^.+?-.+?-.+?T.+?:.+?:.+?Z$/) &&
    isValid(new Date(arg))
  )

export const isOffsetISOString = arg =>
  !!(
    typeof arg === 'string' &&
    arg.match(/^.+?-.+?-.+?T.+?:.+?:.+?(-|\+).+?:/) &&
    isValid(new Date(arg))
  )

export const isYMDString = arg =>
  !!(typeof arg === 'string' && arg.match(/^\d\d\d\d-\d\d-\d\d$/))

/*
This should be used sparingly - all utcified functions already support YMD
For example, this is used when parsing YMD from url on edit chemical app
page and sending ISO to DOS
*/
export const normalizeYMDString = ymdStr => {
  if (!isYMDString(ymdStr)) {
    console.error(`Expected YMDString, received ${ymdStr}`)
  }
  return ymdStr + 'T00:00:00.000Z'
}

export const normalizeEndDateYMDString = ymdStr => {
  if (!isYMDString(ymdStr)) {
    console.error(`Expected YMDString, received ${ymdStr}`)
  }
  return ymdStr + 'T23:59:59.999Z'
}

/*
Yup takes formik's in-state date, which we have enforced to be ISO/YMD
and feeds that into Date constructor (for ISO only). We need to reconvert
it back to ISO. Use this function inside custom date validators
*/
export const fixYupTimezone = date => {
  if (!date) return undefined
  if (dfnsIsDate(date)) {
    return date.toISOString()
  }
  // Noop for YMD
  return date
}

// the internal validation logic for utcify
export const validateArg = arg => {
  // to-do: add a function to throw an error if the value is not a date object
  // throwOnDateObj(value)
  if (isISOString(arg)) {
    return castToLocal(arg)
  }
  if (isYMDString(arg)) {
    return castToLocal(normalizeYMDString(arg))
  }
  if (
    arg !== null &&
    typeof arg === 'object' &&
    Object.values(arg).some(value => isISOString(value) || dfnsIsDate(value))
  ) {
    // to-do: add a function to throw an error if the value is not a date object
    // throwOnDateObj(value)
    const shallow = { ...arg }
    for (const key in shallow) {
      if (isISOString(shallow[key])) {
        shallow[key] = castToLocal(shallow[key])
      } else if (isYMDString(arg)) {
        shallow[key] = castToLocal(normalizeYMDString(shallow[key]))
      }
    }
    return shallow
  }
  return arg
}

/*
This is an adapter for feeding UTC ISOStrings/YMDString into date-fns functions which
expect Date objects and are known to depend on localized time rather than UTC time
Sample proof (see day values, 2 vs 1):
new Date('2020-06-02T09:35:00.870Z')
Mon Jun 01 2020 22:35:00 GMT-1100 (Samoa Standard Time)
DateFns.startOfDay(new Date('2020-06-02T09:35:00.870Z'))
Mon Jun 01 2020 00:00:00 GMT-1100 (Samoa Standard Time) // Wrong
DateFns.startOfDay(new Date('2020-06-02T09:35:00.870Z')).toISOString()
"2020-06-01T11:00:00.000Z" // Wrong
utcify(DateFns.startOfDay)('2020-06-02T09:35:00.870Z')
"2020-06-02T00:00:00.000Z" // Correct
*/
export const utcify = localizedMutator => (...args) => {
  args = args.map(validateArg)
  const result = localizedMutator(...args)
  return dfnsIsDate(result) ? castToUTC(result) : result
}

// Avoids feeding null to new Date and
// avoids feeding empty string to dfnsIsValid
export const isValid = dateString =>
  dfnsIsValid(dateString ? new Date(dateString) : undefined)

export const subYears = utcify(dfnsSubYears)
export const startOfDay = utcify(dfnsStartOfDay)
export const compareAsc = utcify(dfnsCompareAsc)
export const compareDesc = utcify(dfnsCompareDesc)
export const isAfter = utcify(dfnsIsAfter)
export const isBefore = utcify(dfnsIsBefore)
export const isSameDay = utcify(dfnsIsSameDay)
export const isSameYear = utcify(dfnsIsSameYear)
export const endOfDay = utcify(dfnsEndOfDay)
export const subDays = utcify(dfnsSubDays)
export const addDays = utcify(dfnsAddDays)
export const startOfWeek = utcify(dfnsStartOfWeek)
export const endOfWeek = utcify(dfnsEndOfWeek)
export const addWeeks = utcify(dfnsAddWeeks)
export const isWithinInterval = utcify(dfnsIsWithinInterval)
export const subMonths = utcify(dfnsSubMonths)
export const differenceInDays = utcify(dfnsDifferenceInDays)
export const addHours = utcify(dfnsAddHours)
export const isToday = utcify(dfnsIsToday)
export const isTomorrow = utcify(dfnsIsTomorrow)
export const startOfHour = utcify(dfnsStartOfHour)
export const isYesterday = utcify(dfnsIsYesterday)
export const getYear = utcify(dfnsGetYear)
export const parse = utcify(dfnsParse)
export const format = utcify(dfnsFormat)
