import React, { useCallback, forwardRef } from 'react'
import { useTranslation } from 'react-i18next'
import T from 'prop-types'
import Box from '@nutrien/uet-react/Box'
import Paper from '@nutrien/uet-react/Paper'
import List from '@nutrien/uet-react/List'
import Typography from '@nutrien/uet-react/Typography'
import Button from '@nutrien/uet-react/Button'
import Loading from 'components/Loading'
import FarmTreeSearch from 'components/FarmTreeSearch'
import StepProgressCard from 'components/StepProgressCard'
import { SelectFieldsEmptyIcon } from 'components/Icons'
import FarmTreeFilter from 'components/FarmTreeFilter'
import useAuthorshipSession from 'hooks/useAuthorshipSession'
import Entity from './components/Entity'
import NoResults from './components/NoResults'

const FarmTreeContent = forwardRef(
  (
    {
      entities,
      selectedId,
      setSearchString,
      searchString,
      enrolledFieldsDataLoading
    },
    ref
  ) => {
    const clearSearch = useCallback(() => setSearchString(''), [
      setSearchString
    ])
    const { t } = useTranslation()
    const { authorshipSessionActive } = useAuthorshipSession()
    if (!entities) {
      return null
    }

    const displayFarmTreeFilter = () => {
      ref.current.setFloatingPanelVisibility(true)
      return <FarmTreeFilter />
    }

    const displayNoFieldsMessage = () => {
      if (entities.length === 0) {
        return (
          <Box maxHeight="70vh">
            <Box mb={2}>
              <Paper>
                <StepProgressCard
                  data-testid="noFarmTreeResults"
                  border={'none'}
                  title={t('no_field_results')}
                  color="gray.600"
                  icon={SelectFieldsEmptyIcon}
                  iconProps={{
                    viewBox: '0 0 140 62',
                    styles: { fontSize: '140px', height: '62px' }
                  }}>
                  <Box mt={2}>
                    <Typography variant="subtitle1" align="center">
                      {t('try_adjust_filters')}
                    </Typography>
                  </Box>
                  <Button
                    data-testid="clearSearchButton"
                    onClick={() => displayFarmTreeFilter()}>
                    {t('adjust_filters')}
                  </Button>
                </StepProgressCard>
              </Paper>
            </Box>
          </Box>
        )
      } else {
        return (
          <Box
            maxHeight={authorshipSessionActive ? '60vh' : '70vh'}
            overflow="scroll">
            {searchString && entities.length === 0 ? (
              <NoResults clearSearch={clearSearch} />
            ) : (
              entities.map(entity => (
                <Box mb={2} key={entity.uuid || entity.name}>
                  <Paper>
                    <List disablePadding>
                      <Entity
                        entity={entity}
                        selectedId={selectedId}
                        searchString={searchString}
                      />
                    </List>
                  </Paper>
                </Box>
              ))
            )}
          </Box>
        )
      }
    }

    return (
      <Box data-testid="farmTreeContent" p={0}>
        <FarmTreeSearch
          setSearchString={setSearchString}
          searchString={searchString}
        />
        {!enrolledFieldsDataLoading ? displayNoFieldsMessage() : <Loading />}
      </Box>
    )
  }
)

FarmTreeContent.propTypes = {
  entities: T.array.isRequired,
  selectedId: T.string,
  setSearchString: T.func,
  searchString: T.string
}

FarmTreeContent.defaultProps = {
  setSearchString: () => '',
  searchString: ''
}

export default FarmTreeContent
