// This layer's responsibility is styling already functional molecules
// Note: name prop may be using dot-notation, so it's important to always
//       use _get when retrieving the value. Avoid this: values[name]

import React, {
  useLayoutEffect,
  useCallback,
  useMemo,
  useEffect,
  useState
} from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import makeStyles from '@nutrien/uet-react/styles/makeStyles'
import useMediaQuery from '@nutrien/uet-react/useMediaQuery'
import { Formik, Form, useFormikContext } from 'formik'
import _get from 'lodash/get'
import _isNil from 'lodash/isNil'
import _keys from 'lodash/keys'
import _map from 'lodash/map'
import _intersection from 'lodash/intersection'
import Button from '@nutrien/uet-react/Button'
import Box from '@nutrien/uet-react/Box'
import NutrienCheckbox from '@nutrien/uet-react/Checkbox'
import NutrienFormControlLabel from '@nutrien/uet-react/FormControlLabel'
import Grid from '@nutrien/uet-react/Grid'
import Typography from '@nutrien/uet-react/Typography'
import Table from '@nutrien/uet-react/Table'
import TableBody from '@nutrien/uet-react/TableBody'
import TableRow from '@nutrien/uet-react/TableRow'
import TableCell from '@nutrien/uet-react/TableCell'
import Accordion from '@nutrien/uet-react/Accordion'
import AccordionSummary from '@nutrien/uet-react/AccordionSummary'
import AccordionDetails from '@nutrien/uet-react/AccordionDetails'
import ExpandMoreIcon from '@nutrien/uet-react/icons/ExpandMore'
import InfoPopover from 'components/InfoPopover'

import th from 'theme/helpers'
import Intercom from 'components/Intercom'
import useToggle from 'hooks/useToggle'

import {
  ErrorMessage,
  Switch,
  DatePicker,
  TextField,
  Checkbox,
  Autocomplete,
  FIELD_WIDTH_LONG
} from './atoms'
import { SelectGroup, RadioGroup, CheckboxGroup } from './molecules'
import { generateIdFromName } from './utils'

const useStyles = makeStyles({
  label: {
    height: '28px !important',
    marginTop: '8px !important'
  },
  mobileNestedCheckbox: {
    marginLeft: '16px'
  },
  title: {
    fontSize: '16px',
    fontWeight: 600
  }
})

export const StyledForm = ({ children, ...props }) => {
  const isRenderFunction = typeof children === 'function'
  return (
    <Formik {...props}>
      {(...props) => (
        <Form role="form">
          {isRenderFunction ? children(...props) : children}
        </Form>
      )}
    </Formik>
  )
}

const sizeOptions = [
  'small',
  'medium',
  'x-small',
  'spacedBetter',
  'spacedBetterMarginBtm',
  'paddingBtm1Top2',
  'even',
  'even-small',
  'bonsaiSpacing'
]
const defaultSize = sizeOptions[1]
export const FormSection = styled(({ size, children, noPadding }) => {
  const gridProps = noPadding
    ? {}
    : size === 'x-small'
    ? { py: 0, pt: 0, pb: 0, borderBottom: 0 }
    : size === 'small'
    ? { py: 0, pt: 0, pb: 3, borderBottom: 0 }
    : size === 'even-small'
    ? { py: 1, pb: 1 }
    : size === 'even'
    ? { py: 2, pb: 2 }
    : size === 'spacedBetter'
    ? { py: 0, pb: 1 }
    : size === 'spacedBetterMarginBtm'
    ? { py: 0, pb: 3 }
    : size === 'paddingBtm1Top2'
    ? { py: 2, pb: 1 }
    : size === 'bonsaiSpacing'
    ? { pt: 0, pb: 2 }
    : { py: 2, pb: 3 }

  return (
    <Grid container direction="column" component={Box} {...gridProps}>
      {(Array.isArray(children) ? children : [children]).map((child, i) => (
        <Grid key={i} item>
          {child}
        </Grid>
      ))}
    </Grid>
  )
})`
  h3 {
    margin-bottom: ${th.spacing(3)};
  }
`
FormSection.propTypes = {
  /** Determines the spacing between this section and the next */
  size: PropTypes.oneOf(sizeOptions),
  /** Field body */
  children: PropTypes.node
}
FormSection.defaultProps = {
  size: defaultSize,
  children: []
}

export const CollectionFieldControls = ({
  name,
  options = [],
  disabled,
  onSelectAll,
  onClearAll
}) => {
  const { setValues, values } = useFormikContext()
  const currentValue = _get(values, name) || []
  const checked = options.filter(option => {
    if (option.name) {
      return !_isNil(_get(values, option.name))
    }
    return Array.isArray(currentValue) && currentValue.includes(option.value)
  })

  const { t } = useTranslation()
  const handleSelectAll = useCallback(() => {
    setValues({
      ...values,
      [name]: options.map(option => option.value)
    })
  }, [setValues, values, name, options])

  const handleClearAll = useCallback(() => {
    setValues({ ...values, [name]: [] })
  }, [setValues, values, name])

  return (
    <React.Fragment>
      <Button
        type="button"
        disabled={disabled || checked.length === options.length}
        onClick={onSelectAll || handleSelectAll}>
        {t('select') + ' ' + t('All')}
      </Button>
      <Button
        type="button"
        disabled={disabled || checked.length === 0}
        onClick={onClearAll || handleClearAll}>
        {t('clear_all')}
      </Button>
    </React.Fragment>
  )
}
CollectionFieldControls.propTypes = {
  /** Field's unique key used to read and update the value in Formik context */
  name: PropTypes.string,
  /** Prevents user from making changes */
  disabled: PropTypes.bool,
  /** Choices used to populate the field when Select All is pressed */
  options: PropTypes.arrayOf(
    PropTypes.shape({
      /** Field's unique key used to read and update the value in Formik context */
      name: PropTypes.string,
      /** Option's unique value which gets inserted/removed from field's array when checkbox is ticked */
      value: PropTypes.any
    })
  ),
  /** Handler for when Select All button is pressed */
  onSelectAll: PropTypes.func,
  /** Handler for when Clear All button is pressed */
  onClearAll: PropTypes.func
}

export const StyledLabel = ({ name, label, size, textSize }) => (
  <Typography
    style={{ fontSize: textSize }}
    component="label"
    htmlFor={generateIdFromName(name)}
    fontSize={'8px'}
    variant={
      size === 'x-small' ||
      size === 'small' ||
      size === 'spacedBetter' ||
      size === 'spacedBetterMarginBtm'
        ? 'h6'
        : 'h3'
    }>
    {label}
  </Typography>
)

StyledLabel.propTypes = {
  /** Determines the font to be used */
  size: PropTypes.oneOf(sizeOptions),
  /** String or Component for describing the input to the user */
  label: PropTypes.node,
  /** Field's unique key used to generate id passed into htmlFor */
  name: PropTypes.string
}
StyledLabel.defaultProps = {
  size: defaultSize
}

const StyledLabelGroupGrid = styled(({ fixedWidth, ...rest }) => (
  <Grid {...rest} />
))`
  ${({ fixedWidth }) => fixedWidth && `width: ${FIELD_WIDTH_LONG}`}
`

export const StyledLabelGroup = ({
  name,
  label,
  description,
  textSize,
  size,
  optional,
  optionalMarginTop,
  gridProps,
  fixedWidth,
  children
}) => {
  const { t } = useTranslation()
  return (
    <React.Fragment>
      <StyledLabelGroupGrid
        container
        alignItems="center"
        component={Box}
        pb={
          size === 'x-small' ||
          size === 'small' ||
          size === 'even' ||
          description
            ? 0
            : size === 'spacedBetter' || size === 'spacedBetterMarginBtm'
            ? 0.5
            : 2
        }
        fixedWidth={fixedWidth ?? optional}
        {...gridProps}>
        <Grid item>
          <StyledLabel
            textSize={textSize}
            name={name}
            label={label}
            size={size}
          />
        </Grid>
        {(Array.isArray(children) ? children : [children]).map((child, i) => (
          <Grid key={i} item>
            {child}
          </Grid>
        ))}
        {optional && (
          <Grid item>
            <Typography
              variant="body2"
              style={{ marginLeft: '.25rem', marginTop: optionalMarginTop }}>
              {`(${t('optional')})`}
            </Typography>
          </Grid>
        )}
      </StyledLabelGroupGrid>
      {description && (
        <Box py={2}>
          <Typography variant="subtitle2">{description}</Typography>
        </Box>
      )}
    </React.Fragment>
  )
}
StyledLabelGroup.propTypes = {
  /** Determines the font to be used */
  size: PropTypes.oneOf(sizeOptions),
  /** String or Component for describing the input to the user */
  label: PropTypes.node,
  /** Additional String or Component which appears under the label */
  description: PropTypes.node,
  /** Field's unique key used to generate id passed into htmlFor */
  name: PropTypes.string,
  /** Used to set Top margin for optional designation */
  optionalMarginTop: PropTypes.string,
  /** Content to be inlined with the label */
  children: PropTypes.node
}
StyledLabelGroup.defaultProps = {
  size: defaultSize,
  children: [],
  optionalMarginTop: '0rem'
}

export const StyledSwitch = ({ size, label, name, disabled }) => {
  return (
    <FormSection size={size}>
      <Grid container alignItems="center">
        <StyledLabel name={name} label={label} size={size} />
        <Switch name={name} disabled={disabled} />
      </Grid>
      <ErrorMessage name={name} />
    </FormSection>
  )
}
StyledSwitch.propTypes = {
  /** Determines the size and spacing of the label */
  size: PropTypes.oneOf(sizeOptions),
  /** String or Component for describing the input to the user */
  label: PropTypes.node,
  /** Additional String or Component which appears under the label */
  description: PropTypes.node,
  /** Field's unique key used to read and update the value in Formik context */
  name: PropTypes.string.isRequired,
  /** Prevents user from making changes */
  disabled: PropTypes.bool
}
StyledSwitch.defaultProps = {
  size: defaultSize
}

export const StyledDatePicker = ({
  size,
  label,
  description,
  fullwidth,
  name,
  disabled,
  placeholder,
  hasHelpText,
  hintText,
  ...rest
}) => {
  const { t } = useTranslation()
  return (
    <FormSection size={size}>
      {label && (
        <StyledLabelGroup
          name={name}
          label={label}
          description={description}
          size={size}
          {...rest}
        />
      )}
      <DatePicker
        name={name}
        disabled={disabled}
        placeholder={placeholder}
        invalidDateMessage={null} // To hide error other than yup's
        maxDateMessage={null} // To hide error other than yup's
        minDateMessage={null} // To hide error other than yup's
        {...rest}
      />
      <ErrorMessage name={name} />
      {hintText && (
        <Box fontSize={14} color="grey.600">
          <Typography>{hintText}</Typography>
        </Box>
      )}
      {hasHelpText && (
        <InfoPopover
          infoLabel={
            <Box ml={-1} mt={1}>
              <Button>
                <Typography variant="h5" color="green.brand">
                  {`${t('disabledDateTitle')}?`}
                </Typography>
              </Button>
            </Box>
          }>
          <Box mt={1}>
            <Typography variant="subtitle1" color="grey.800">
              {t('disabledDateMessage')}
            </Typography>
          </Box>
        </InfoPopover>
      )}
    </FormSection>
  )
}
StyledDatePicker.propTypes = {
  /** Determines the size and spacing of the label */
  size: PropTypes.oneOf(sizeOptions),
  /** String or Component for describing the input to the user */
  label: PropTypes.node,
  /** Additional String or Component which appears under the label */
  description: PropTypes.node,
  /** Field's unique key used to read and update the value in Formik context */
  name: PropTypes.string.isRequired,
  /** Prevents user from making changes */
  disabled: PropTypes.bool,
  /** String displayed in the input when value is considered empty */
  placeholder: PropTypes.string,
  /** Shows a button why the date might be disabled */
  hasHelpText: PropTypes.bool,
  /** String displayed underneath the field */
  hintText: PropTypes.string
}
StyledDatePicker.defaultProps = {
  size: defaultSize
}

// error messages don't appear to user unless the Formik value is touched === true
// this component will set touched to true if it is false and the value changes
export const DatePickerTouch = props => {
  const { name } = props
  const { initialValues, values, setFieldTouched, touched } = useFormikContext()

  useEffect(() => {
    if (values[name] !== initialValues[name] && !touched[name]) {
      setFieldTouched(name, true)
    }
  }, [initialValues, name, setFieldTouched, touched, values])

  return <StyledDatePicker {...props} />
}

export const FormSectionToggle = ({
  label,
  description,
  name,
  disabled,
  gridProps,
  size,
  children
}) => {
  const { values } = useFormikContext()
  const currentValue = _get(values, name)
  return (
    <FormSection>
      <StyledLabelGroup
        size={size}
        name={name}
        label={label}
        description={description}
        gridProps={gridProps}>
        <Switch name={name} disabled={disabled} />
      </StyledLabelGroup>
      {!!currentValue && !disabled && children}
    </FormSection>
  )
}
FormSectionToggle.propTypes = {
  /** String or Component for describing the input to the user */
  label: PropTypes.node,
  /** Additional String or Component which appears under the label */
  description: PropTypes.node,
  /** Field's unique key used to read and update the value in Formik context */
  name: PropTypes.string.isRequired,
  /** Prevents user from making changes */
  disabled: PropTypes.bool,
  /** Determines the spacing between this section and the next */
  size: PropTypes.oneOf(sizeOptions),
  /** Content to be hidden when toggle is off */
  children: PropTypes.node
}

export const StyledMultiChoice = ({
  size,
  label,
  description,
  name,
  'data-testid': dataTestId,
  disabled,
  options,
  hideControls,
  hideError,
  boldItemText,
  ...rest
}) => {
  const { values, setFieldValue } = useFormikContext()
  const currentValue = _get(values, name)

  useEffect(() => {
    if (!Array.isArray(currentValue)) {
      setFieldValue(name, [])
    }
  }, [setFieldValue, currentValue, name])
  return (
    <FormSection size={size}>
      {label && (
        <StyledLabelGroup
          name={name}
          label={label}
          description={description}
          size={size}>
          {!hideControls && (
            <CollectionFieldControls
              name={name}
              options={options}
              disabled={disabled}
            />
          )}
        </StyledLabelGroup>
      )}
      <CheckboxGroup
        name={name}
        data-testid={dataTestId}
        disabled={disabled}
        options={options}
        boldItemText={boldItemText}
        {...rest}
      />
      {!hideError && <ErrorMessage name={name} />}
    </FormSection>
  )
}
StyledMultiChoice.propTypes = {
  /** Determines the size and spacing of the label */
  size: PropTypes.oneOf(sizeOptions),
  /** String or Component for describing the input to the user */
  label: PropTypes.node,
  /** Additional String or Component which appears under the label */
  description: PropTypes.node,
  /** Field's unique key used to read and update the value in Formik context */
  name: PropTypes.string.isRequired,
  /** Prevents user from making changes */
  disabled: PropTypes.bool,
  /** Show FieldControls (select all/clear all) */
  hideControls: PropTypes.bool,
  /** Hide The error component */
  hideError: PropTypes.bool,
  /** Choices available to the user - where each receives its own checkbox */
  options: PropTypes.arrayOf(
    PropTypes.shape({
      /** Option's unique value which gets inserted/removed from field's array when checkbox is ticked */
      value: PropTypes.any.isRequired,
      /** String or Component for describing the option to the user */
      label: PropTypes.any.isRequired
    })
  ).isRequired
}
StyledMultiChoice.defaultProps = {
  size: defaultSize,
  hideControls: false,
  hideError: false
}

const resolveVariant = (variant, options) =>
  (!variant && options.length < 8) || variant === 'radio'
    ? 'radio'
    : variant || 'dropdown'

const resolveSingleChoiceController = (variant, options) => {
  if (variant === 'radio') {
    return RadioGroup
  }
  if (variant === 'dropdown' || variant === 'dropdown-narrow') {
    return SelectGroup
  }
  return options.length >= 8 ? SelectGroup : RadioGroup
}

export const StyledSingleChoice = ({
  size,
  label,
  description,
  name,
  'data-testid': dataTestId,
  disabled,
  options,
  value,
  variant,
  onChange,
  optionTypographyVariant,
  unit,
  width,
  alignOptions,
  isRow,
  spaceRight,
  noPadding,
  condensedRadioSpacing,
  customError
}) => {
  variant = resolveVariant(variant, options)
  const Controller = resolveSingleChoiceController(variant, options)
  return (
    <FormSection size={size} noPadding={noPadding}>
      {label && (
        <StyledLabelGroup
          name={name}
          label={label}
          description={description}
          size={size}
        />
      )}
      <Grid container spacing={1} alignItems="center">
        <Grid item xs={variant === 'dropdown-narrow' && unit ? null : true}>
          <Controller
            name={name}
            TypographyVariant={optionTypographyVariant}
            data-testid={dataTestId}
            disabled={disabled}
            options={options}
            value={value}
            width={variant === 'dropdown-narrow' ? '136px' : width}
            alignOptions={alignOptions}
            isRow={isRow}
            spaceRight={spaceRight}
            {...(!!onChange && { onChange })}
            {...(unit && variant !== 'radio' && { unit })}
            condensedRadioSpacing={condensedRadioSpacing}
          />
        </Grid>
        {!!unit && (
          <Grid item xs>
            <Typography variant="body2">{unit}</Typography>
          </Grid>
        )}
      </Grid>
      {!customError && (
        <ErrorMessage data-testid={`${dataTestId}-error`} name={name} />
      )}
      {!!customError && customError}
    </FormSection>
  )
}
StyledSingleChoice.propTypes = {
  /** Determines the size and spacing of the label */
  size: PropTypes.oneOf(sizeOptions),
  /** String or Component for describing the input to the user */
  label: PropTypes.node,
  /** Additional String or Component which appears under the label */
  description: PropTypes.node,
  /** Field's unique key used to read and update the value in Formik context */
  name: PropTypes.string.isRequired,
  /** Prevents user from making changes */
  disabled: PropTypes.bool,
  /** Choices available to the user - where each receives its own radio button or dropdown item */
  options: PropTypes.arrayOf(
    PropTypes.shape({
      /** Option's unique value used to populate the field */
      value: PropTypes.any,
      /** String or Component for describing the option to the user */
      label: PropTypes.any.isRequired,
      /** Allow individual options to be disabled */
      disabled: PropTypes.bool
    })
  ).isRequired,
  /** Input type. By default uses radio for when there is less than 8 items and dropdown otherwise */
  variant: PropTypes.oneOf(['dropdown', 'dropdown-narrow', 'radio']),
  /** Callback for when the value changes */
  onChange: PropTypes.func,
  /** The units to display after a dropdown (not available on radio) */
  units: PropTypes.string,
  /** Align radio button labels with specific flexbox align-items value */
  alignOptions: PropTypes.string,
  /** Do not apply any padding to the form section */
  noPadding: PropTypes.bool,
  /** Custom error message component */
  customError: PropTypes.node
}
StyledSingleChoice.defaultProps = {
  size: defaultSize
}

export const StyledNumInput = ({
  size,
  label,
  description,
  name,
  disabled,
  'data-testid': dataTestId,
  placeholder,
  unit,
  unitName,
  unitOptions,
  customError,
  optional,
  showAction,
  onActionClick,
  actionLabel,
  ...rest
}) => {
  const { values } = useFormikContext()
  const currentUnit = _get(values, unitName)
  const unitType = unitName && unitOptions ? 'write' : 'read'
  return (
    <FormSection size={size}>
      {label && (
        <StyledLabelGroup
          name={name}
          label={label}
          description={description}
          size={size}
          optional={optional}
          {...rest}
        />
      )}
      <Grid container alignItems="center">
        <TextField
          name={name}
          data-testid={`test-${name}`}
          type="number"
          disabled={disabled}
          placeholder={placeholder}
          connect={unitType === 'write' ? 'right' : undefined}
          {...rest}
        />
        {unit && unitType === 'read' && (
          <Box ml={1}>
            <Typography variant="body2">{unit}</Typography>
          </Box>
        )}
        {unitType === 'write' && (
          <SelectGroup
            name={unitName}
            value={currentUnit}
            options={unitOptions}
            connect="left"
            width="auto"
            disabled={disabled}
          />
        )}
        {showAction && (
          <Button variant="text" onClick={onActionClick}>
            {actionLabel}
          </Button>
        )}
      </Grid>
      {!customError && (
        <ErrorMessage data-testid={`${dataTestId}-error`} name={name} />
      )}
      {!customError && unitName && (
        <ErrorMessage data-testid={`${dataTestId}-error`} name={unitName} />
      )}
      {!!customError && customError}
    </FormSection>
  )
}
StyledNumInput.propTypes = {
  /** Determines the size and spacing of the label */
  size: PropTypes.oneOf(sizeOptions),
  /** String or Component for describing the input to the user */
  label: PropTypes.node,
  /** Additional String or Component which appears under the label */
  description: PropTypes.node,
  /** Field's unique key used to read and update the value in Formik context */
  name: PropTypes.string.isRequired,
  /** Prevents user from making changes */
  disabled: PropTypes.bool,
  /** String displayed in the input when value is considered empty */
  placeholder: PropTypes.string,
  /** Unit displayed after the input. Mutually exclusive with unitName and unitOptions props */
  unit: PropTypes.string,
  /** Unit field's unique key used to read and update the value in Formik context */
  unitName: PropTypes.string,
  /** Unit choices available to the user - where each receives its own radio button or dropdown item */
  unitOptions: PropTypes.arrayOf(
    PropTypes.shape({
      /** Option's unique value used to populate the unit field */
      value: PropTypes.any.isRequired,
      /** String or Component for describing the option to the user */
      label: PropTypes.any.isRequired
    })
  ),
  showAction: PropTypes.bool,
  onActionClick: PropTypes.func,
  actionLabel: PropTypes.string
}
StyledNumInput.defaultProps = {
  size: defaultSize
}

const Unit = styled(Box)`
  ${({ disabled }) =>
    disabled
      ? css`
          color: ${th.palette('grey.A400')};
        `
      : ''}
`
const StyledTable = styled(Table)`
  tr:not(.field-error-row) {
    height: 56px;
  }
  tr > * {
    border: 0;
    padding: 0;
  }
  ${th.breakpoints.down('sm')`
    th:not(.field-error-cell) {
      width: 150px;
    }
  `}
  ${th.breakpoints.up('md')`
    th:not(.field-error-cell) {
      width: 1px;
      white-space: nowrap;
    }
    td {
      padding-left: ${th.spacing(4)};
    }
  `}
`
const StyledAccordion = styled(Accordion)`
  box-shadow: none;
  &:last-child {
    .MuiCollapse-entered {
      padding-bottom: ${th.spacing(2)};
    }
  }
`
const StyledAccordionSummary = styled(AccordionSummary)`
  padding: 0;
  align-items: flex-start;
  .MuiAccordionSummary-content {
    margin: 0;
  }
  .MuiAccordionSummary-expandIcon {
    padding: ${th.spacing(1)};
  }
`
const StyledAccordionDetails = styled(AccordionDetails)`
  padding: 0;
`

// This field can have one of three following values:
// null - disabled
// empty string '' - unpopulated
// number - populated
export const StyledMultiChoiceNum = ({ size, label, disabled, options }) => {
  const {
    errors,
    values,
    setFieldValue,
    setValues,
    setErrors
  } = useFormikContext()
  const classes = useStyles()
  const [expanded, toggleExpanded] = useToggle(true)
  const errorKeys = useMemo(() => _keys(errors), [errors])
  const optionNames = useMemo(() => _map(options, 'name'), [options])
  const mobile = useMediaQuery(theme => theme.breakpoints.down('md'))
  useEffect(() => {
    if (_intersection(errorKeys, optionNames)) toggleExpanded(true)
  }, [errorKeys, optionNames, toggleExpanded])

  // Manage bulk population / depopulation
  const handleSelectAll = useCallback(() => {
    const newValues = options.reduce((accum, option) => {
      accum[option.name] = _get(values, option.name) || ''
      return accum
    }, {})
    setValues({
      ...values,
      ...newValues
    })
  }, [options, setValues, values])

  const handleClearAll = useCallback(() => {
    const newValues = options.reduce((accum, option) => {
      if (option?.subfieldOptions) {
        option.subfieldOptions.map(({ name }) => (accum[name] = null))
      }
      accum[option.name] = null
      return accum
    }, {})
    setValues({
      ...values,
      ...newValues
    })
    setErrors({})
  }, [options, setErrors, setValues, values])

  const renderCondition = (formValue, sign, value) =>
    sign === 'greater'
      ? formValue > value
      : sign === 'less'
      ? formValue < value
      : formValue === value

  return (
    <FormSection size={size}>
      <StyledAccordion expanded={expanded} onChange={toggleExpanded}>
        <StyledAccordionSummary expandIcon={<ExpandMoreIcon />}>
          <StyledLabelGroup label={label} size={size}>
            <span
              onFocus={event => event.stopPropagation()}
              onClick={event => event.stopPropagation()}>
              <CollectionFieldControls
                options={options}
                disabled={disabled}
                onSelectAll={handleSelectAll}
                onClearAll={handleClearAll}
              />
            </span>
          </StyledLabelGroup>
        </StyledAccordionSummary>
        <StyledAccordionDetails>
          <Grid container direction="column">
            <StyledTable>
              <TableBody>
                {options.map(
                  ({
                    name,
                    label,
                    unit,
                    placeholder,
                    subfieldTitle,
                    sign,
                    value,
                    max,
                    subfieldOptions
                  }) => {
                    const currentValue = _get(values, name)
                    // We must allow empty string, otherwise the field will autoclose on backspace
                    const isValid =
                      typeof currentValue === 'number' || currentValue === ''
                    return (
                      <React.Fragment key={name}>
                        <TableRow>
                          <TableCell component="th" scope="row">
                            <Checkbox
                              id={generateIdFromName(name)}
                              key={name}
                              name={name}
                              disabled={disabled}
                              checked={isValid}
                              label={label}
                              onChange={event => {
                                const checked = event.target.checked ? '' : null
                                setFieldValue(name, checked)
                              }}
                            />
                          </TableCell>
                          <TableCell>
                            <Grid container alignItems="center">
                              <TextField
                                name={name}
                                type="number"
                                value={
                                  typeof currentValue === 'number'
                                    ? currentValue
                                    : ''
                                }
                                disabled={disabled || !isValid}
                                placeholder={placeholder}
                              />
                              <Unit disabled={disabled || !isValid} ml={1}>
                                {unit}
                              </Unit>
                            </Grid>
                          </TableCell>
                        </TableRow>
                        {_get(errors, name) && (
                          <TableRow className="field-error-row">
                            <TableCell
                              component="th"
                              scope="row"
                              colSpan={2}
                              className="field-error-cell">
                              <ErrorMessage name={name} />
                            </TableCell>
                          </TableRow>
                        )}
                        {subfieldOptions &&
                          currentValue <= max &&
                          renderCondition(currentValue, sign, value) && (
                            <>
                              <TableRow className={classes.label}>
                                <TableCell colSpan={3}>
                                  <Box ml={mobile && 3.5}>{subfieldTitle}</Box>
                                </TableCell>
                              </TableRow>
                              {subfieldOptions.map(({ name, label }) => (
                                <TableRow className={classes.label} key={label}>
                                  <TableCell colSpan={3}>
                                    <Checkbox
                                      name={name}
                                      label={label}
                                      id={generateIdFromName(name)}
                                      key={name}
                                      className={
                                        mobile && classes.mobileNestedCheckbox
                                      }
                                      checked={_get(values, name)}
                                      onChange={event => {
                                        setValues({
                                          ...values,
                                          [name]: event.target.checked
                                        })
                                      }}
                                    />
                                  </TableCell>
                                </TableRow>
                              ))}
                            </>
                          )}
                      </React.Fragment>
                    )
                  }
                )}
              </TableBody>
            </StyledTable>
          </Grid>
        </StyledAccordionDetails>
      </StyledAccordion>
    </FormSection>
  )
}
StyledMultiChoiceNum.propTypes = {
  /** Determines the size and spacing of the label */
  size: PropTypes.oneOf(sizeOptions),
  /** String or Component for describing the input to the user */
  label: PropTypes.node,
  /** Prevents user from making changes */
  disabled: PropTypes.bool,
  /** Choices available to the user - where each receives its own checkbox */
  options: PropTypes.arrayOf(
    PropTypes.shape({
      /** Field's unique key used to read and update the value in Formik context */
      name: PropTypes.string.isRequired,
      /** String or Component for describing the option to the user */
      label: PropTypes.any.isRequired,
      /** Unit displayed after the input */
      unit: PropTypes.any.isRequired,
      /** String displayed in the input when value is considered empty */
      placeholder: PropTypes.string,
      // Tile used on subfield
      subfieldTitle: PropTypes.string,
      // used for subfield conditional rendering can be greater or less
      sign: PropTypes.string,
      // value used on the right side of condition
      value: PropTypes.number,
      // name and label used to render nested checkboxes
      subfieldOptions: PropTypes.arrayOf(
        PropTypes.shape({
          // name and label for subfields
          name: PropTypes.string,
          label: PropTypes.string
        })
      )
    })
  ).isRequired
}
StyledMultiChoiceNum.defaultProps = {
  size: defaultSize
}

export const NestedStyledMultiChoiceNum = ({
  size,
  parent,
  disabled,
  options
}) => {
  const {
    name: parentName,
    label: parentLabel,
    disabled: parentDisabled
  } = parent
  const {
    errors,
    values,
    setValues,
    setFieldValue,
    setErrors
  } = useFormikContext()
  const classes = useStyles()
  const mobile = useMediaQuery(theme => theme.breakpoints.down('md'))
  const [titleBoxChecked, setTitleBoxChecked] = useState(false)

  // determine if title check box value is indeterminate, false, or true
  useEffect(() => {
    const checkedOptions = options?.filter(option => {
      return values[option.name] !== null
    })
    setFieldValue(
      parentName,
      checkedOptions?.length > 0 && checkedOptions?.length < options.length
        ? 'indeterminate'
        : !!checkedOptions?.length
    )
    values[parentName] && values[parentName] !== 'indeterminate'
      ? setTitleBoxChecked(true)
      : setTitleBoxChecked(false)
  }, [options, parentName, setFieldValue, values])

  const handleParentTick = useCallback(
    event => {
      if (values[parentName] === 'indeterminate' || values[parentName]) {
        const newValues = options.reduce((accum, option) => {
          if (option?.subfieldOptions) {
            option.subfieldOptions.map(({ name }) => (accum[name] = null))
          }
          accum[option.name] = null
          return accum
        }, {})
        setValues({
          ...values,
          ...newValues
        })
        setTitleBoxChecked(false)
      }
      if (!values[parentName]) {
        const newValues = options.reduce((accum, option) => {
          accum[option.name] = _get(values, option.name) || ''
          return accum
        }, {})
        setValues({
          ...values,
          ...newValues
        })
        setTitleBoxChecked(true)
      }
      setErrors({})
    },
    [options, parentName, setErrors, setValues, values]
  )

  const renderCondition = (formValue, sign, value) =>
    sign === 'greater'
      ? formValue > value
      : sign === 'less'
      ? formValue < value
      : formValue === value

  return (
    <FormSection size={size}>
      <Checkbox
        name={parentName}
        label={<span className={classes.title}>{parentLabel}</span>}
        classes={classes.title}
        checked={titleBoxChecked}
        indeterminate={_get(values, parentName) === 'indeterminate'}
        disabled={parentDisabled}
        onChange={handleParentTick}
      />
      <Grid container direction="column">
        <StyledTable>
          <TableBody>
            {options.map(
              ({
                name,
                label,
                unit,
                placeholder,
                subfieldTitle,
                sign,
                value,
                max,
                subfieldOptions
              }) => {
                const currentValue = _get(values, name)
                // We must allow empty string, otherwise the field will autoclose on backspace
                const isValid =
                  typeof currentValue === 'number' || currentValue === ''
                return (
                  <React.Fragment key={name}>
                    <TableRow>
                      <TableCell component="th" scope="row">
                        <Checkbox
                          id={generateIdFromName(name)}
                          key={name}
                          name={name}
                          disabled={disabled}
                          checked={isValid}
                          label={label}
                          onChange={event => {
                            const checked = event.target.checked ? '' : null
                            setFieldValue(name, checked)
                          }}
                        />
                      </TableCell>
                      <TableCell>
                        <Grid container alignItems="center">
                          <TextField
                            name={name}
                            type="number"
                            value={
                              typeof currentValue === 'number'
                                ? currentValue
                                : ''
                            }
                            disabled={disabled || !isValid}
                            placeholder={placeholder}
                          />
                          <Unit disabled={disabled || !isValid} ml={1}>
                            {unit}
                          </Unit>
                        </Grid>
                      </TableCell>
                    </TableRow>
                    {_get(errors, name) && (
                      <TableRow className="field-error-row">
                        <TableCell
                          component="th"
                          scope="row"
                          colSpan={2}
                          className="field-error-cell">
                          <ErrorMessage name={name} />
                        </TableCell>
                      </TableRow>
                    )}
                    {subfieldOptions &&
                      currentValue <= max &&
                      renderCondition(currentValue, sign, value) && (
                        <>
                          <TableRow className={classes.label}>
                            <TableCell colSpan={3}>
                              <Box ml={mobile && 3.5}>{subfieldTitle}</Box>
                            </TableCell>
                          </TableRow>
                          {subfieldOptions.map(({ name, label }) => (
                            <TableRow className={classes.label} key={label}>
                              <TableCell colSpan={3}>
                                <Checkbox
                                  name={name}
                                  label={label}
                                  id={generateIdFromName(name)}
                                  key={name}
                                  className={
                                    mobile && classes.mobileNestedCheckbox
                                  }
                                  checked={_get(values, name)}
                                  onChange={event => {
                                    setValues({
                                      ...values,
                                      [name]: event.target.checked
                                    })
                                  }}
                                />
                              </TableCell>
                            </TableRow>
                          ))}
                        </>
                      )}
                  </React.Fragment>
                )
              }
            )}
          </TableBody>
        </StyledTable>
      </Grid>
    </FormSection>
  )
}
NestedStyledMultiChoiceNum.propTypes = {
  /** Determines the size and spacing of the label */
  size: PropTypes.oneOf(sizeOptions),
  /** Title of grouping and used for a single check box that functions as select all/clear of grouping */
  parent: PropTypes.objectOf(
    PropTypes.shape({
      /** Field's unique key used to read and update the value in Formik context */
      name: PropTypes.string,
      /** String or Component for describing the title to the user */
      label: PropTypes.string.isRequired,
      /** Prevents user from making changes */
      disabled: PropTypes.bool.isRequired
    })
  ).isRequired,
  /** Prevents user from making changes */
  disabled: PropTypes.bool,
  /** Choices available to the user - where each receives its own checkbox */
  options: PropTypes.arrayOf(
    PropTypes.shape({
      /** Field's unique key used to read and update the value in Formik context */
      name: PropTypes.string.isRequired,
      /** String or Component for describing the option to the user */
      label: PropTypes.any.isRequired,
      /** Unit displayed after the input */
      unit: PropTypes.any.isRequired,
      /** String displayed in the input when value is considered empty */
      placeholder: PropTypes.string,
      // Tile used on subfield
      subfieldTitle: PropTypes.string,
      // used for subfield conditional rendering can be greater or less
      sign: PropTypes.string,
      // value used on the right side of condition
      value: PropTypes.number,
      // name and label used to render nested checkboxes
      subfieldOptions: PropTypes.arrayOf(
        PropTypes.shape({
          // name and label for subfields
          name: PropTypes.string,
          label: PropTypes.string
        })
      )
    })
  ).isRequired
}
NestedStyledMultiChoiceNum.defaultProps = {
  size: defaultSize
}

export const StyledTextInput = ({
  size,
  label,
  description,
  name,
  disabled,
  placeholder,
  multiline,
  optional,
  width,
  ...rest
}) => {
  return (
    <FormSection size={size}>
      <StyledLabelGroup
        name={name}
        label={label}
        description={description}
        size={size}
        optional={optional}
        fixedWidth={false}
        {...rest}
      />
      <TextField
        name={name}
        disabled={disabled}
        placeholder={placeholder}
        multiline={multiline}
        width={width}
        {...rest}
      />
      <ErrorMessage name={name} />
    </FormSection>
  )
}
StyledTextInput.propTypes = {
  /** Determines the size and spacing of the label */
  size: PropTypes.oneOf(sizeOptions),
  /** String or Component for describing the input to the user */
  label: PropTypes.node,
  /** Additional String or Component which appears under the label */
  description: PropTypes.node,
  /** Field's unique key used to read and update the value in Formik context */
  name: PropTypes.string.isRequired,
  /** Prevents user from making changes */
  disabled: PropTypes.bool,
  /** String displayed in the input when value is considered empty */
  placeholder: PropTypes.string,
  /** Uses textarea instead of regular input */
  multiline: PropTypes.bool,
  /** To override fixed width */
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
}
StyledTextInput.defaultProps = {
  size: defaultSize
}

const useScrollToError = () => {
  const { isSubmitting, errors, touched } = useFormikContext()
  const errorCount = Object.keys(errors).length
  const touchedCount = Object.keys(touched).length
  useLayoutEffect(() => {
    if (isSubmitting && errorCount && touchedCount) {
      const el = document.querySelector('.field-error')
      if (el) el.scrollIntoView({ behavior: 'smooth', block: 'center' })
    }
  }, [isSubmitting, errorCount, touchedCount])
}

export const FloatingPanel = styled(Grid).attrs({
  container: true,
  direction: 'column',
  alignItems: 'flex-end'
})`
  position: sticky;
  z-index: 1200;
  bottom: 0;
  left: 0;
  pointer-events: none;
`
export const FloatingControlsWrapper = styled(Grid).attrs({
  item: true,
  container: true,
  justify: 'flex-end',
  spacing: 2
})`
  padding: ${th.spacing(0, 1, 2, 2)};
  background-color: ${th.palette('background.default')};
  pointer-events: auto;
`

const IntercomGrid = styled(Grid).attrs({
  component: Box,
  item: true,
  pb: 3,
  pr: 1
})`
  pointer-events: auto;
`

export const FloatingControls = ({
  cancelLink,
  CancelProps,
  handleCancel, // TODO: Rename to onCancel for consistency
  saveLabel,
  saveEnabled,
  hideIntercom
}) => {
  const { dirty, isSubmitting } = useFormikContext()
  const { t } = useTranslation()
  const cancelProps = cancelLink
    ? { component: Link, to: cancelLink, ...CancelProps }
    : undefined
  useScrollToError()
  return (
    <FloatingPanel>
      {!hideIntercom && (
        <IntercomGrid>
          <Intercom />
        </IntercomGrid>
      )}
      <FloatingControlsWrapper>
        <Grid item>
          <Button
            type="button"
            variant="outlined"
            disabled={isSubmitting}
            onClick={handleCancel}
            {...cancelProps}>
            {t('cancel')}
          </Button>
        </Grid>
        <Grid item>
          <Button
            type="submit"
            variant="contained"
            disabled={
              isSubmitting || !_isNil(saveEnabled) ? !saveEnabled : !dirty
            }>
            {saveLabel || t('save')}
          </Button>
        </Grid>
      </FloatingControlsWrapper>
    </FloatingPanel>
  )
}
FloatingControls.propTypes = {
  /** Url to redirect to when Cancel button is pressed */
  cancelLink: PropTypes.string,
  /** Custom handler fired when Cancel button is pressed */
  handleCancel: PropTypes.func,
  /** Option to manually enable the save button */
  saveEnabled: PropTypes.bool,
  /** Option to manually enable the save button */
  saveLabel: PropTypes.string
}

export const StyledAutocomplete = ({
  size,
  label,
  description,
  name,
  'data-testid': dataTestId,
  disabled,
  placeholder,
  options,
  width,
  onChange,
  getOptionLabel
}) => (
  <FormSection size={size}>
    <StyledLabelGroup
      name={name}
      label={label}
      description={description}
      size={size}
    />
    <Autocomplete
      name={name}
      data-testid={dataTestId}
      disabled={disabled}
      placeholder={placeholder}
      options={options}
      width={width}
      {...(!!onChange && { onChange })}
      {...(!!getOptionLabel && { getOptionLabel })}
    />
    <ErrorMessage data-testid={`${dataTestId}-error`} name={name} />
  </FormSection>
)
StyledAutocomplete.propTypes = {
  /** Determines the size and spacing of the label */
  size: PropTypes.oneOf(sizeOptions),
  /** String or Component for describing the input to the user */
  label: PropTypes.node,
  /** Additional String or Component which appears under the label */
  description: PropTypes.node,
  /** Field's unique key used to read and update the value in Formik context */
  name: PropTypes.string.isRequired,
  /** Prevents user from making changes */
  disabled: PropTypes.bool,
  /** String displayed in the input when value is considered empty */
  placeholder: PropTypes.string,
  /** Choices available to the user in the autocomplete dropdown */
  options: PropTypes.arrayOf(PropTypes.string).isRequired,
  /** Width of the field */
  width: PropTypes.string,
  /** Callback for when user leaves input mode */
  onBlur: PropTypes.func,
  /** Callback for when the value changes */
  onChange: PropTypes.func,
  /** String for assigning the test id */
  'data-testid': PropTypes.string,
  /** Callback for when user presses any key */
  onInputChange: PropTypes.func,
  /** Callback for get the option label */
  getOptionLabel: PropTypes.func
}
StyledAutocomplete.defaultProps = {
  size: defaultSize
}

export const StyledMultiChoiceSubCrops = ({
  size,
  label,
  description,
  name,
  'data-testid': dataTestId,
  disabled,
  options,
  hideControls,
  hideError,
  textSize,
  boldItemText,
  ...rest
}) => {
  const { values, setFieldValue } = useFormikContext()
  const [selectAllChecked, setSelectAllChecked] = useState(false)
  const { t } = useTranslation()
  const currentValue = _get(values, name)
  useEffect(
    boldItemText => {
      if (!Array.isArray(currentValue)) {
        setFieldValue(name, [])
      }
    },
    [setFieldValue, currentValue, name]
  )

  // loop through all of the secondary options to get all of the "values"
  // and flatten the array.
  const availableOptions = options
    .map(option =>
      option.secondaryOptions.map(secondaryOption => secondaryOption.value)
    )
    .flat()

  const handleSelectAll = useCallback(() => {
    if (selectAllChecked) {
      setFieldValue(name, [])
    } else {
      setFieldValue(name, availableOptions)
    }

    setSelectAllChecked(!selectAllChecked)
  }, [selectAllChecked, setFieldValue, name, availableOptions])

  useEffect(() => {
    setSelectAllChecked(currentValue?.length === availableOptions.length)
  }, [availableOptions, currentValue, setSelectAllChecked])

  return (
    <FormSection size={size}>
      <NutrienFormControlLabel
        style={{ paddingBottom: '1.5em' }}
        control={
          <NutrienCheckbox
            checked={selectAllChecked}
            onClick={handleSelectAll}
          />
        }
        label={`${t('Select')} ${t('All')}`}
      />

      {options.map(({ label, secondaryOptions }) => (
        <Box mb={2} key={label}>
          <StyledLabelGroup
            textSize={textSize || '18px'}
            name={name}
            label={label}
            key={label}
            description={description}
            size="spacedBetter">
            {!hideControls && (
              <CollectionFieldControls
                name={name}
                options={options}
                disabled={disabled}
              />
            )}
          </StyledLabelGroup>
          <Grid>
            <CheckboxGroup
              textSize={textSize || '14px'}
              textWidth={'25%'}
              name={name}
              data-testid={dataTestId}
              disabled={disabled}
              options={secondaryOptions}
              boldItemText={boldItemText}
              {...rest}
            />
          </Grid>
        </Box>
      ))}
      {!hideError && <ErrorMessage name={name} />}
    </FormSection>
  )
}

StyledMultiChoiceSubCrops.propTypes = {
  /** Determines the size and spacing of the label */
  size: PropTypes.oneOf(sizeOptions),
  /** String or Component for describing the input to the user */
  label: PropTypes.node,
  /** Additional String or Component which appears under the label */
  description: PropTypes.node,
  /** Field's unique key used to read and update the value in Formik context */
  name: PropTypes.string.isRequired,
  /** Prevents user from making changes */
  disabled: PropTypes.bool,
  /** Show FieldControls (select all/clear all) */
  hideControls: PropTypes.bool,
  /** Hide The error component */
  hideError: PropTypes.bool,
  /** Choices available to the user - where each receives its own checkbox */
  options: PropTypes.arrayOf(
    PropTypes.shape({
      /** Option's unique value which gets inserted/removed from field's array when checkbox is ticked */
      value: PropTypes.any.isRequired,
      /** String or Component for describing the option to the user */
      label: PropTypes.any.isRequired
    })
  ).isRequired
}
StyledMultiChoiceSubCrops.defaultProps = {
  size: defaultSize,
  hideControls: false,
  hideError: false
}
