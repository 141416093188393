import React, { useState, useEffect, useContext, useCallback } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import jwt from 'jwt-decode'
import Box from '@nutrien/uet-react/Box'
import useFlags from 'hooks/useFlags'
import { AuthContext } from 'components/Auth/authentication-context'
import Loading from 'components/Loading'
import { AUTH_TYPE_SSO_PENDING } from 'components/Auth/constants/authTypes'
import usePendo from 'hooks/usePendo'
import useQueryParams from 'hooks/useQueryParams'

import useDashboardLink from 'hooks/useDashboardLink'
import { gqlHooksCache } from '../../graphql'
import SelectCountryDialog from './components/SelectCountryDialog'
import { LoginAndSignupTaps } from './LoginAndSignupTaps'
import { AuthenticationContainer } from './AuthenticationContainer'
import { ResetEmailSentMessage } from './ResetEmailSentMessage'

export const useAuthContext = () => {
  return useContext(AuthContext)
}

export function LoadingAuthForm() {
  return (
    <Box minWidth="260px" minHeight="260px" display="flex" center="xy">
      <Loading />
    </Box>
  )
}

// Get user email from error description
const GetUserEmail = errorDescription => {
  return errorDescription.toLowerCase().includes('email=')
    ? errorDescription.split('email=')[1]
    : ''
}

const Authentication = () => {
  const { t } = useTranslation()
  const flags = useFlags()
  const history = useHistory()
  const params = useParams()
  const [activeTab, setActiveTab] = useState(
    params.activeTab ? params.activeTab : 'login'
  )
  const [isSSOLoading, setIsSSOLoading] = useState(false)
  const [nutrienUserExists, setNutrienUserExists] = useState(false)
  const [openSelectCountryDialog, setOpenSelectCountryDialog] = useState(false)
  const [SSOAccessToken, setSSOAccessToken] = useState(false)
  const { initialize: initializePendo } = usePendo()
  const {
    ssoLogin,
    isAuthenticated,
    isSSOLoading: ssoLoading,
    isSSOAuthenticated,
    getSSOAccessToken,
    createSSOSession,
    setAuthType,
    getAuthType,
    user,
    authentication
  } = useAuthContext()

  const baseRoute = window.location.pathname.replace('/', '')
  const isLegacy = baseRoute === 'legacy-login'
  const isSSOCallBack = baseRoute === 'auth'
  const isSignup = baseRoute === 'signup'
  const isResetEmailSent =
    baseRoute === '/legacy-login/emailsent'.replace('/', '')
  const isNutrienUser =
    !isLegacy &&
    !isSSOCallBack &&
    !isSignup &&
    !isResetEmailSent &&
    !isAuthenticated

  const screeningAnswers = JSON.parse(
    sessionStorage.getItem('screeningAnswers')
  )
  const {
    redirect,
    error,
    error_description: errorDescription
  } = useQueryParams()

  if (
    error?.toLowerCase() === 'access_denied' &&
    errorDescription?.toLowerCase().includes('email verification required')
  ) {
    const email = GetUserEmail(errorDescription)
    history.push('/relogin', {
      email: email
    })
  }

  useEffect(() => {
    if (isLegacy) {
      const authType = getAuthType()
      if (authType === AUTH_TYPE_SSO_PENDING) {
        setAuthType(null)
      }
    }
  }, [isLegacy, setAuthType, getAuthType])

  const dashboardLink = useDashboardLink()
  useEffect(() => {
    if (isAuthenticated && user != null) {
      let url = redirect
      if (!url?.length) {
        url = dashboardLink
      }
      history.push(url)
    }
  }, [dashboardLink, history, redirect, isAuthenticated, user])

  // initialize pendo. the logic on when it runs
  // is in the initialize function.
  useEffect(() => {
    initializePendo()
    gqlHooksCache.clear()

    // disabling this rule since we only want this to run on login/logout
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated, isSSOAuthenticated])

  /** Switch between signup and login */
  const handleTabChange = useCallback(
    (event, newValue) => {
      switch (newValue) {
        case 'signup':
          history.push('/signup')
          break
        default:
          history.replace('/legacy-login')
          break
      }
      setActiveTab(newValue)
    },
    [history]
  )

  /**
   * Return SSO redirect uri with redirect intact
   */
  const getSSOLoginRedirectUri = useCallback(redirect => {
    if (redirect?.length) {
      return `${window.location.origin}/auth?redirect=${redirect}`
    }
    return `${window.location.origin}/auth`
  }, [])

  const handleFailedSSOCallback = useCallback(
    (accessToken = '') => {
      const usedAccessToken = SSOAccessToken || accessToken
      const keyPrefix = 'https://nutrienagsolutions.com/'
      const jwtClaims = usedAccessToken ? jwt(usedAccessToken) : false
      const isEmailVerified = jwtClaims[`${keyPrefix}email_verified`]
      const email = jwtClaims[`${keyPrefix}email`]

      email?.endsWith('@nutrien.com')
        ? history.replace('/legacy-login')
        : isEmailVerified
        ? history.push('/login-failed', {
            email: email
          })
        : history.push('/relogin', {
            email: email
          })
    },
    [history, SSOAccessToken]
  )

  const handleSelectSSOUserCountry = async country => {
    setOpenSelectCountryDialog(false)
    try {
      await authentication.submitAgreeAgribleCom(country, SSOAccessToken)
      await createSSOSession()
    } catch (error) {
      handleFailedSSOCallback()
    }
  }

  useEffect(() => {
    if (isNutrienUser) {
      ssoLogin({
        redirectUri: getSSOLoginRedirectUri(redirect)
      })
    }
  }, [getSSOLoginRedirectUri, isNutrienUser, redirect, ssoLogin])

  useEffect(() => {
    if (nutrienUserExists) ssoLogin()
  }, [nutrienUserExists, ssoLogin])

  /** Handling  ssoCallback */
  useEffect(() => {
    const isSSOCallBack = window.location.pathname.replace('/', '') === 'auth'
    if (isSSOCallBack && isSSOAuthenticated && !SSOAccessToken) {
      setIsSSOLoading(true)
      exchangeTokenAndHandleErrors({
        authentication,
        getSSOAccessToken,
        setSSOAccessToken,
        createSSOSession,
        setAuthType,
        handleFailedSSOCallback,
        setOpenSelectCountryDialog
      })
    }
  }, [
    authentication,
    setAuthType,
    createSSOSession,
    getSSOAccessToken,
    isSSOAuthenticated,
    handleFailedSSOCallback,
    setOpenSelectCountryDialog,
    SSOAccessToken
  ])

  const redirectToAuth0Signup = useCallback(() => {
    ssoLogin({
      initialScreen: 'signUp',
      redirectUri: getSSOLoginRedirectUri(redirect)
    })
  }, [ssoLogin, getSSOLoginRedirectUri, redirect])

  const redirectToAuth0Signin = useCallback(() => {
    const emailRegex = /membershipEmail=([^&]+)/
    const match = redirect?.match(emailRegex)

    let email = ''
    if (match) {
      email = decodeURIComponent(match[1])
    }

    ssoLogin({
      redirectUri: getSSOLoginRedirectUri(redirect),
      login_hint: email
    })
  }, [ssoLogin, getSSOLoginRedirectUri, redirect])

  /** Tab switching */
  useEffect(() => {
    const { pathname } = history.location
    switch (pathname) {
      case '/signup':
        setActiveTab('signup')
        if (flags.agribleSsoSignup) {
          redirectToAuth0Signup()
        }
        break
      case '/login':
        if (flags.agribleSsoSignup) {
          redirectToAuth0Signin()
        }
        break
      default:
        setActiveTab('login')
        break
    }
    // disabling this eslint rule since we ONLY want this
    // effect to run when the history.location changes.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history.location])

  return ssoLoading || nutrienUserExists ? (
    <Loading />
  ) : (
    <>
      <SelectCountryDialog
        open={openSelectCountryDialog}
        handleSelect={handleSelectSSOUserCountry}
      />
      <AuthenticationContainer>
        {isResetEmailSent && <ResetEmailSentMessage />}

        <LoginAndSignupTaps
          t={t}
          agribleSSOSignup={flags.agribleSsoSignup}
          activeTab={activeTab}
          isSSOLoading={isSSOLoading}
          setNutrienUserExists={setNutrienUserExists}
          screeningAnswers={screeningAnswers}
          handleTabChange={handleTabChange}
          getSSOLoginRedirectUri={getSSOLoginRedirectUri}
          redirectToAuth0Signup={redirectToAuth0Signup}></LoginAndSignupTaps>
      </AuthenticationContainer>
    </>
  )
}

Authentication.displayName = 'Authentication'
export default Authentication

async function exchangeTokenAndHandleErrors({
  authentication,
  getSSOAccessToken,
  setSSOAccessToken,
  createSSOSession,
  setOpenSelectCountryDialog,
  handleFailedSSOCallback
}) {
  const accessToken = await getSSOAccessToken()
  setSSOAccessToken(accessToken)
  try {
    const result = await authentication.exchangeSSOToken(accessToken)
    const SSOcountry = result.country

    if (!result.is_email_verified) {
      handleFailedSSOCallback(accessToken)
      return
    }
    if (!SSOcountry) {
      setOpenSelectCountryDialog(true)
      return
    } else if (!result.is_agreed_agrible_com) {
      try {
        await authentication.submitAgreeAgribleCom(SSOcountry, accessToken)
      } catch (error) {
        if (error.message !== 'BAD_REQUEST') {
          handleFailedSSOCallback(accessToken)
        }
      }
    }
    await createSSOSession()
  } catch (error) {
    handleFailedSSOCallback(accessToken)
  }
}
