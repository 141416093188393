import { ReportTypes } from './commonConst'

export const ReportTypeOptions = [
  {
    value: ReportTypes.ConsolidatedCarbonContractInfoReport,
    label: 'Consolidated Carbon Contract Info Report'
  },
  {
    value: ReportTypes.CarbonContractInfoReport,
    label: 'Carbon Contract Info Report'
  },
  {
    value: ReportTypes.InsuranceInfo,
    label: 'Insurance Info Report'
  },
  { value: ReportTypes.CPGReport, label: 'CPG Report' },
  {
    value: ReportTypes.FieldBoundariesReport,
    label: 'Field Boundaries Report'
  },
  { value: ReportTypes.GrowerReport, label: 'Grower Reports' },
  { value: ReportTypes.ProjectStatusReport, label: 'Project status Report' },
  { value: ReportTypes.QCReport, label: 'QC Report' }
]

const noRegionSupportReports = new Set([
  // Add reports that do not support regions.
  ReportTypes.CarbonContractInfoReport,
  ReportTypes.InsuranceInfo
])

export const regionSupportedReports = new Set([])
ReportTypeOptions.forEach(
  report =>
    !noRegionSupportReports.has(report.value) &&
    regionSupportedReports.add(report.value)
)
