/* eslint-disable one-var */
/* eslint-disable sort-keys */

import { BASELINE_SEASON_EVENTS } from './commonConst'

// SUSTAINABILITY PERMISSIONS
const GROWER = 'grower'
const EMPLOYEE = 'employee'
const STAKEHOLDER = 'stakeholder'

/**
 * A dictionary of sustainability permissions
 */

export const SUSTAINABILITY_PERMISSIONS = Object.freeze({
  GROWER,
  EMPLOYEE,
  STAKEHOLDER
})

// MEMBERSHIP STATUS
const ACTIVE = 'Active'
const DROPPED = 'Dropped'
const IVINTATION_DECLINED = 'InvitationDeclined'
const INVITATION_PENDING = 'InvitationPending'
const NOT_INVITED = 'NotInvited'
const REMOVED = 'Removed'

/**
 * A dictionary of membership status
 */
export const MEMBERSHIP_STATUS = Object.freeze({
  ACTIVE,
  DROPPED,
  IVINTATION_DECLINED,
  INVITATION_PENDING,
  NOT_INVITED,
  REMOVED
})

// MANAGE PROJECTS STEPS
const BUILD_CROP_ROTATIONS = 'build_crop_rotations'
const COMMIT_FIELD_ACTIVITY = 'commit_field_activity'
const CREATE_PLAN = 'create_plan'
const DOCUMENT_BASELINE = 'document_baseline'
const LOG_FIELD_ACTIVITY = 'log_field_activity'
const REVIEW_AND_SUBMIT = 'review_and_submit'
const SELECT_FIELDS = 'select_fields'
const SUBMIT_BASELINE = 'submit_baseline'
const SUBMIT_PLAN = 'submit_plan'
const REVIEW_DATA = 'review_data'
const FIELD_STORY = 'field-story'
const VIEW_FIELD_ANALYTICS = 'field_report'
const VIEW_GROWER_REPORTS = 'grower_reports'

/**
 * A dictionary of manage project steps
 */
export const PROJECT_STEPS = Object.freeze({
  BUILD_CROP_ROTATIONS,
  COMMIT_FIELD_ACTIVITY,
  CREATE_PLAN,
  DOCUMENT_BASELINE,
  SUBMIT_BASELINE,
  LOG_FIELD_ACTIVITY,
  REVIEW_AND_SUBMIT,
  SELECT_FIELDS,
  SUBMIT_PLAN,
  REVIEW_DATA,
  FIELD_STORY,
  VIEW_FIELD_ANALYTICS,
  VIEW_GROWER_REPORTS
})

/**
 * A dictionary of project step routes
 */
export const PROJECT_STEP_ROUTES = Object.freeze({
  [BUILD_CROP_ROTATIONS]: 'build-rotations',
  [CREATE_PLAN]: 'create-plan',
  [DOCUMENT_BASELINE]: 'document-baseline',
  [LOG_FIELD_ACTIVITY]: 'log-field-activity',
  [SELECT_FIELDS]: 'select-fields',
  [FIELD_STORY]: 'field-story'
})

/**
 * A dictionary of manage project steps label
 */
export const PROJECT_STEPS_LABEL = Object.freeze({
  [BUILD_CROP_ROTATIONS]: 'build_rotations',
  [COMMIT_FIELD_ACTIVITY]: 'Commit',
  [CREATE_PLAN]: 'create_plan',
  [DOCUMENT_BASELINE]: 'document_baseline',
  [LOG_FIELD_ACTIVITY]: 'log_field_activity',
  [REVIEW_AND_SUBMIT]: 'review_and_submit',
  [SELECT_FIELDS]: 'select_fields',
  [SUBMIT_BASELINE]: 'submit',
  [SUBMIT_PLAN]: 'submit',
  [REVIEW_DATA]: 'reviewData',
  [VIEW_GROWER_REPORTS]: 'grower_reports',
  [VIEW_FIELD_ANALYTICS]: 'field_report'
})

/**
 * A dictionary of manage project steps categories
 */
export const PROJECT_STEPS_CATEGORIES = Object.freeze([
  Object.freeze({
    dueDateKey: 'enrollmentEndDate',
    label: 'enroll',
    steps: Object.freeze([CREATE_PLAN, SELECT_FIELDS, SUBMIT_PLAN])
  }),
  Object.freeze({
    dueDateKey: 'planDeadline',
    label: 'setup_project',
    steps: Object.freeze([
      BUILD_CROP_ROTATIONS,
      DOCUMENT_BASELINE,
      REVIEW_AND_SUBMIT,
      SUBMIT_BASELINE
    ])
  }),
  Object.freeze({
    dueDateKey: 'campaignEndDate',
    label: 'commit_field_data',
    steps: Object.freeze([COMMIT_FIELD_ACTIVITY, LOG_FIELD_ACTIVITY])
  }),
  Object.freeze({
    dueDateKey: '',
    label: 'access_reports',
    steps: Object.freeze([VIEW_FIELD_ANALYTICS, VIEW_GROWER_REPORTS])
  })
])

export const STATUS_ELIGIBLE_FIELDS = 'eligibleFields',
  STATUS_ENROLLED_FIELDS = 'enrolledFields',
  STATUS_INELIGIBLE_FIELDS = 'ineligibleFields'

export const CREATE_PLAN_STEPS = Object.freeze({
  CROPPING_SEASON: {
    label: 'CroppingSeason',
    stepIndex: 0
  },
  PLAN: {
    label: 'Plan',
    stepIndex: 1
  }
})

export const CREATE_ROTATION_STEPS = Object.freeze({
  SEASONS: {
    label: 'seasons',
    stepIndex: 0
  },
  FIELDS: {
    label: 'fields',
    stepIndex: 1
  }
})

export const CROPPING_SEASON_STEPS = Object.freeze({
  SEASON: {
    label: 'Season',
    stepIndex: 0
  },
  FIELDS: {
    label: 'fields',
    stepIndex: 1
  }
})

export const EDIT_CROPPING_SEASON_DATES_STEPS = Object.freeze({
  SEASON: {
    label: 'date',
    stepIndex: 0
  },
  FIELDS: {
    label: 'fields',
    stepIndex: 1
  }
})

export const FERTILIZER_STEPS = Object.freeze({
  FERTILIZER: {
    label: 'fertilizer',
    stepIndex: 0
  },
  FIELDS: {
    label: 'fields',
    stepIndex: 1
  }
})
export const IRRIGATION_STEPS = Object.freeze({
  IRRIGATION: {
    label: 'Irrigation',
    stepIndex: 0
  },
  FIELDS: {
    label: 'fields',
    stepIndex: 1
  }
})
export const CROP_PROTECTION_STEPS = Object.freeze({
  FERTILIZER: {
    label: 'Crop Protection',
    stepIndex: 0
  },
  FIELDS: {
    label: 'fields',
    stepIndex: 1
  }
})
export const TILLAGE_STEPS = Object.freeze({
  TILLAGE: {
    label: 'Tillage',
    stepIndex: 0
  },
  FIELDS: {
    label: 'fields',
    stepIndex: 1
  }
})

export const BEST_PRACTICES_STEPS = Object.freeze({
  PRACTICES: {
    label: 'Practices',
    stepIndex: 0
  },
  FIELDS: {
    label: 'fields',
    stepIndex: 1
  }
})

export const HARVEST_STEPS = Object.freeze({
  YIELD: {
    label: 'Yield',
    stepIndex: 0
  },
  FIELDS: {
    label: 'fields',
    stepIndex: 1
  }
})

export const HARVEST_FULL_DRAWER_STEPS = Object.freeze({
  YIELD: {
    label: 'Harvest',
    stepIndex: 0
  },
  FIELDS: {
    label: 'fields',
    stepIndex: 1
  }
})

export const PLANTING_COMPLETION_DRAWER_STEPS = Object.freeze({
  YIELD: {
    label: 'Planting',
    stepIndex: 0
  },
  FIELDS: {
    label: 'fields',
    stepIndex: 1
  }
})

const CREATE = 'create'
const EDIT = 'edit'

export const DRAWER_VARIANTS = Object.freeze({
  CREATE,
  EDIT
})

const IS_BASELINE_REQUIRED = 'isBaselineRequired'
const IS_ROTATION_SEASON = 'isRotationSeason'
const IS_TARGET_SEASON = 'isTargetSeason'
const ALL_SEASONS = 'allSeasons'

export const CHIP_TYPES = Object.freeze({
  IS_BASELINE_REQUIRED,
  IS_ROTATION_SEASON,
  IS_TARGET_SEASON,
  ALL_SEASONS
})

export const SEASON_CHIPS_FILTERS = Object.freeze({
  ALL_SEASONS: { type: 'ALL_SEASONS_FILTER_TYPE', labelKey: 'All Seasons' },
  HISTORICAL_ROTATION: {
    type: IS_ROTATION_SEASON,
    labelKey: 'Historical Rotation'
  },
  TARGET_YEAR: {
    type: IS_TARGET_SEASON,
    labelKey: 'target_year'
  },
  REQUIRED: {
    type: IS_BASELINE_REQUIRED,
    labelKey: 'required'
  },
  BASELINE: {
    type: IS_BASELINE_REQUIRED,
    labelKey: 'Baseline'
  }
})

const N_STABILIZER = 'Nitrification Inhibitor'
const SLOW_RELEASE = 'Slow Release'

export const STABILIZER_VARIANTS = Object.freeze({
  N_STABILIZER,
  SLOW_RELEASE
})

const BEST_YIELD = 'bestHistoricYield'
const BEST_FERTILIZER = 'bestHistoricFertilizer'

/**
 * A dictionary of best historical data keys based on the event name and event type
 */
export const BEST_HISTORIC_DATA = Object.freeze({
  [BASELINE_SEASON_EVENTS.FERTILIZER_APPLICATION_BASELINE]: BEST_FERTILIZER,
  [BASELINE_SEASON_EVENTS.FERTILIZERAPPLICATION_BASELINE]: BEST_FERTILIZER,
  [BASELINE_SEASON_EVENTS.HARVEST_COMPLETION_BASELINE]: BEST_YIELD,
  [BASELINE_SEASON_EVENTS.HARVESTCOMPLETION_BASELINE]: BEST_YIELD,
  [BASELINE_SEASON_EVENTS.HARVEST_MIN]: BEST_YIELD
})
