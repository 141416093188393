import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState
} from 'react'
import PropTypes from 'propTypes'
import { useParams } from 'react-router-dom'
import { useManualQuery } from 'graphql-hooks'

import { AuthContext } from 'components/Auth/authentication-context'

const GET_CAMPAIGN_QUERY = `
  query FetchCampaign($campaignUuid: String!) {
    getCommunityNode (kind: Campaign, uuid: $campaignUuid) {
      uuid
      name
      kind
      ... on Campaign {
        years
        shortDescription
        enrollmentStartDate
        enrollmentEndDate
        campaignStartDate
        campaignEndDate
        planDeadline
        isLandOwnerRequired
        specificCropIds
        isFtm
        isCfa
        isComet
        isCarnmpp
        isNerp
      }
    }
  }
`

export const ENROLLED_FIELDS_QUERY = `
  query EnrolledFieldsQuery($membershipUuid: String!) {
    getFieldsByCampaignEnrollmentStatus (membershipUuid: $membershipUuid) {
      enrolledFields {
        uuid
        name
        hasLandRights
        locked
      }
      eligibleFields {
        uuid
        name
        hasLandRights
        locked
      }
      ineligibleFields {
        uuid
        name
        hasLandRights
        locked
      }
  }
}
`

const FETCH_CROPS_WITH_SUB_CROPS_QUERY = `
  query FetchCropsWithSubCrops {
    listCropsWithSubCrops {
      id
      name
      specificCrops { id name, isFsSupported }
    }
  }
`

const GET_MEMBERSHIP_QUERY = `
query GetMembership($profileUuid: String!, $campaignUuid: String!){
  getMembership(profileUuid: $profileUuid, campaignUuid:$campaignUuid){
    uuid
    status
    parent {
      uuid
      kind
      name
    }
    region {
      uuid
      kind
      name
    }
  }
}
`

const GET_PROJECT_STEPS = `
  query getProjectSteps($membershipId: String!) {
    getProjectSteps (membershipId: $membershipId) {
      projectSteps {
        id
        step
        isCompleted
        fieldsCompletedCount
        fieldsCompletedArea
      }
      projectPercentCompleted
      fieldsTotalCount
      fieldsTotalArea
      areaUnits
      totalCarbonCredits
    }
  }
`

const initialContext = {}
export const StepperContext = createContext(initialContext)

const StepperProvider = ({ children }) => {
  const { id: campaignUuid } = useParams()
  const { user } = useContext(AuthContext)
  const [snackbarMessage, setSnackbarMessage] = useState(null)

  const [
    fetchCampaignData,
    {
      data: campaignData,
      loading: campaignLoading,
      error: campaignError,
      refetch: refetchCampaignData
    }
  ] = useManualQuery(GET_CAMPAIGN_QUERY, { variables: { campaignUuid } })

  const [
    fetchCropsWithSubCrops,
    {
      data: cropsWithSubCrops,
      loading: cropWithSubCropsLoading,
      error: cropsWithSubCropsError,
      refetch: refetchcropsWithSubCrops
    }
  ] = useManualQuery(FETCH_CROPS_WITH_SUB_CROPS_QUERY)

  const [
    fetchEnrolledFieldsData,
    {
      data: enrolledFieldsData,
      loading: enrolledFieldsLoading,
      error: enrolledFieldsError,
      refetch: refetchEnrolledFields
    }
  ] = useManualQuery(ENROLLED_FIELDS_QUERY)

  const [
    fetchMembershipData,
    {
      data: membershipData,
      loading: membershipLoading,
      error: membershipError,
      refetch: refetchMembershipData
    }
  ] = useManualQuery(GET_MEMBERSHIP_QUERY, {
    variables: { profileUuid: user.id, campaignUuid },
    skip: !user?.id
  })

  const [
    fetchProjectStepsData,
    {
      data: projectStepsData,
      loading: projectStepsLoading,
      error: projectStepsError,
      refetch: refetchProjectStepsData
    }
  ] = useManualQuery(GET_PROJECT_STEPS)

  const fetchRequiredData = useCallback(async () => {
    fetchCampaignData()
    fetchCropsWithSubCrops()

    // await is needed since the Project Step and Enrolled Fields queries
    // require a data from the membership
    const { data: fetchMembershipResult } = await fetchMembershipData()

    fetchEnrolledFieldsData({
      variables: {
        membershipUuid: fetchMembershipResult?.getMembership?.[0].uuid
      }
    })

    fetchProjectStepsData({
      variables: {
        membershipId: fetchMembershipResult?.getMembership?.[0].uuid
      }
    })
  }, [
    fetchCampaignData,
    fetchCropsWithSubCrops,
    fetchEnrolledFieldsData,
    fetchMembershipData,
    fetchProjectStepsData
  ])

  const handleSnackbarClose = useCallback(() => {
    setSnackbarMessage(null)
  }, [setSnackbarMessage])

  // on Mount
  useEffect(() => {
    fetchRequiredData()

    // Triggering this effect only on mount
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const context = {
    // Campaign Query
    campaignData: campaignData?.getCommunityNode,
    campaignError,
    campaignLoading,

    // Crops With Subcrops Query
    cropsWithSubCrops: cropsWithSubCrops?.listCropsWithSubCrops,
    cropWithSubCropsLoading,
    cropsWithSubCropsError,

    // Enrolled Fields Query
    enrolledFieldsData: enrolledFieldsData?.getFieldsByCampaignEnrollmentStatus,
    enrolledFieldsError,
    enrolledFieldsLoading,

    // Functions
    fetchRequiredData,
    handleSnackbarClose,

    // Membership Query
    membershipData: membershipData?.getMembership?.[0],
    membershipError,
    membershipLoading,

    // Project Steps Query
    projectStepsData: projectStepsData?.getProjectSteps,
    projectStepsError,
    projectStepsLoading,

    // Refetches
    refetchCampaignData,
    refetchcropsWithSubCrops,
    refetchEnrolledFields,
    refetchMembershipData,
    refetchProjectStepsData,

    // Snackbar State
    setSnackbarMessage,
    snackbarMessage
  }

  return (
    <StepperContext.Provider value={context}>
      {children}
    </StepperContext.Provider>
  )
}

StepperProvider.propTypes = {
  children: PropTypes.node.isRequired
}

export default StepperProvider
