import React from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'
import CircularProgress from '@nutrien/uet-react/CircularProgress'

const SIZE = 33

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  min-height: ${SIZE}px;
  height: 100%;
  ${({ isFloating }) =>
    isFloating &&
    css`
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
    `}
  > * {
    width: ${SIZE}px;
    height: ${SIZE}px;
  }
`

const Loading = ({ isLoading, isFloating, children }) =>
  isLoading ? (
    <Wrapper isFloating={isFloating}>
      <CircularProgress size={SIZE} />
    </Wrapper>
  ) : typeof children === 'function' ? (
    children()
  ) : (
    children
  )

Loading.propTypes = {
  isLoading: PropTypes.bool,
  isFloating: PropTypes.bool,
  children: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.element,
    PropTypes.node
  ])
}

Loading.defaultProps = {
  isLoading: true,
  isFloating: false,
  children: () => {}
}

export default Loading
