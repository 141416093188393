import _get from 'lodash/get'
import _filter from 'lodash/filter'
import _last from 'lodash/last'
import _find from 'lodash/find'
import mapValues from 'lodash/mapValues'
import pickBy from 'lodash/pickBy'
import size from 'lodash/size'
import { createSelector } from 'reselect'
import {
  ENTITY_TYPE_FIELDS,
  ENTITY_TYPE_FARMS,
  ENTITY_TYPE_ENTERPRISE_GROUPS,
  ENTITY_TYPE_ORGS,
  UUID_MY_ACCOUNT,
  PROJECT_STEPS,
  ENTITY_TYPE_ROTATION_GROUP,
  CAMPAIGN_TYPES
} from 'const'
import { isFarm, isField, isOrganization } from 'helpers'
import keyBy from 'lodash/keyBy'

export const selectFarmTree = state => state.farmTree.entities

export const selectFarmTreeLoadingState = state => state.farmTree.isLoading

export const selectSelectedId = state => state.farmTree.selectedId

const selectDescendantsOfType = (farmTree, entityId, type) => {
  const loop = entity =>
    entity.type === type
      ? [entity]
      : (entity.childrenUUIDs || []).reduce(
          (acc, c) => acc.concat(loop(farmTree[c])),
          []
        )

  return loop(farmTree[entityId] || {})
}

export const selectGroupsFor = createSelector(
  selectFarmTree,
  (_, orgId) => orgId,
  (farmTree, orgId) =>
    farmTree[orgId].group
      ? [farmTree[orgId].group] // If we have just one group, it was pruned earlier, so return only that
      : selectDescendantsOfType(farmTree, orgId, ENTITY_TYPE_ENTERPRISE_GROUPS)
)

export const selectFarmsFor = createSelector(
  selectFarmTree,
  (_, entityId) => entityId,
  (farmTree, entityId) =>
    selectDescendantsOfType(farmTree, entityId, ENTITY_TYPE_FARMS)
)

export const selectFieldsFor = createSelector(
  selectFarmTree,
  (_, entityId) => entityId,
  (farmTree, entityId) =>
    selectDescendantsOfType(farmTree, entityId, ENTITY_TYPE_FIELDS)
)

export const selectAncestors = createSelector(
  selectFarmTree,
  (_, entityId) => entityId,
  (_, __, downToType) => downToType,
  (farmTree, entityId, downToType) => {
    const loop = uuid => {
      const entity = farmTree[_get(farmTree, [uuid, 'parentUUID'])]
      return entity
        ? [
            entity,
            ...(downToType && entity.type === downToType
              ? []
              : loop(entity.uuid))
          ]
        : []
    }

    return loop(entityId)
  }
)

export const getMoveFieldOptions = (state, farmId) => {
  const farmTree = selectFarmTree(state)
  const farmParent = farmTree[farmTree[farmId].parentUUID]
  return farmParent.childrenUUIDs.map(uuid => farmTree[uuid])
}

export const selectAllFarms = (state, farmId, projectStep) => {
  const useUnfilteredFarms = PROJECT_STEPS.FIELD_STORY === projectStep
  const farmTree = selectFarmTree(state)
  const allFarms = state.farmTree.allFarms
  const farmParent =
    farmTree[
      farmTree[farmId]?.parentUUID ||
        _find(allFarms, { uuid: farmId }).parentUUID
    ]
  if (!farmParent) return state.farmTree.allFarms

  const currentOrgFarms = useUnfilteredFarms
    ? farmParent.childrenUUIDs.map(uuid => farmTree[uuid])
    : state?.farmTree.allFarms?.filter(
        farm => farm.parentUUID === farmParent.uuid
      )

  return currentOrgFarms
}

export const selectAllFields = state =>
  selectEntitiesByType(state, ENTITY_TYPE_FIELDS)

export const selectChildren = (state, entityId) => {
  const farmTree = selectFarmTree(state)
  const entity = farmTree[entityId]
  return entity.childrenUUIDs.map(uuid => farmTree[uuid])
}

export const selectFarmForField = (state, entityId) => {
  const parent =
    state.farmTree.entities[state.farmTree.entities[entityId]?.parentUUID] ||
    _find(state.farmTree.allFarms, {
      uuid: state.farmTree.entities[entityId]?.parentUUID
    })
  const farm = parent || selectAncestors(state, entityId, ENTITY_TYPE_FARMS)[0]
  return farm
}

export const selectFieldById = (state, entityId) => {
  return state.farmTree.entities[entityId]
}

export const selectOrgFor = (state, entityId) => {
  const entity = selectFarmTree(state)[entityId] || {}
  return entity.type === ENTITY_TYPE_ORGS
    ? entity
    : _last(selectAncestors(state, entityId, ENTITY_TYPE_ORGS))
}

export const selectSelectedOrgFor = (state, entityId) => {
  const flattenedEntities = state.farmTree.entities
  const entity =
    flattenedEntities[entityId] || Object.values(flattenedEntities)[0]
  return selectOrgFor(state, entity?.uuid)
}

export const selectSelectedEntity = createSelector(
  selectSelectedId,
  selectFarmTree,
  (selectedId, farmTree) => farmTree[selectedId]
)

export const selectSelectedEntityWithFarmInfo = createSelector(
  selectSelectedEntity,
  selectFarmTree,
  (selectedEntity, farmTree) => ({
    ...selectedEntity,
    farm: farmTree[selectedEntity.parentUUID]
  })
)

export const selectEntitiesByType = createSelector(
  selectFarmTree,
  (_, type) => type,
  (farmTree, type) => _filter(farmTree, { type })
)

export const selectAllOrgs = state =>
  selectEntitiesByType(state, ENTITY_TYPE_ORGS)

export const selectOwnedOrg = createSelector(
  selectFarmTree,
  farmTree =>
    _get(
      farmTree,
      // first children of my account is always the owned org
      _get(farmTree, [UUID_MY_ACCOUNT, 'childrenUUIDs', 0])
    ) ??
    // it is safe to assume that the first org that doesn't have an owner
    // is an owned org. This is only ran if there is no all-organization
    Object.values(farmTree).find(
      entity => entity.type === 'orgs' && !entity.owner
    )
)

export const selectPrimaryOrg = createSelector(selectFarmTree, farmTree => {
  const orgs = Object.values(farmTree).filter(({ type }) =>
    isOrganization(type)
  )

  if (orgs.length === 1) {
    return orgs[0]
  }

  return orgs.find(({ owner }) => owner === null)
})

export const selectFarmTreeLoading = state => state.farmTree.isLoading

export const selectAllCroppingSeasons = state =>
  state.farmTree.allCroppingSeasons

export const selectCampaignDetails = state => state.farmTree.campaignData

export const getCampaignType = state => {
  const { campaignData } = state.farmTree
  if (campaignData?.isNerp) {
    return CAMPAIGN_TYPES.NERP
  } else if (campaignData?.isCarnmpp) {
    return CAMPAIGN_TYPES.SNO
  } else if (campaignData?.isFtm || campaignData?.isCfa) {
    return CAMPAIGN_TYPES.MEAS
  }
}

export const selectCropList = state => state.farmTree.cropsWithSubCrops
export const selectCropNameIndex = state => {
  const cropList = selectCropList(state)
  if (!cropList) return null
  return {
    crops: mapValues(keyBy(cropList, 'id'), 'name'),
    specificCrops: cropList.reduce((acc, { specificCrops }) => {
      return { ...acc, ...mapValues(keyBy(specificCrops, 'id'), 'name') }
    }, {})
  }
}

export const selectCropRotations = state => state.farmTree.cropRotations

export const selectCustomRotationId = state =>
  state.farmTree.cropRotations.find(({ seasons }) => !seasons.length).id

export const selectCustomRotationIsSelected = createSelector(
  selectCustomRotationId,
  selectSelectedId,
  (customRotationId, selectedId) => customRotationId === selectedId
)
const formatCropRotationsAsEntities = (cropRotations, entities) =>
  cropRotations?.reduce((acc, rotation) => {
    const childrenUUIDs = Object.values(entities).reduce(
      (acc, { uuid, rotationId }) =>
        rotation.id === rotationId ? acc.concat(uuid) : acc,
      []
    )
    return childrenUUIDs.length
      ? {
          ...acc,
          [rotation.id]: {
            uuid: rotation.id,
            name: rotation.rotationName,
            seasons: rotation.seasons,
            baselineStatusIncomplete: rotation.baselineStatusIncomplete,
            type: ENTITY_TYPE_ROTATION_GROUP,
            childrenUUIDs
          }
        }
      : acc
  }, {})

export const selectBuildCropRotationsFarmTree = createSelector(
  selectFarmTree,
  selectCropRotations,
  (farmTree, cropRotations) => {
    if (!cropRotations) {
      return {}
    }
    const fields = pickBy(farmTree, {
      type: ENTITY_TYPE_FIELDS
    })

    const cropRotationEntities = formatCropRotationsAsEntities(
      cropRotations,
      fields
    )

    return {
      ...mapValues(fields, field => ({
        ...field,
        parentUUID: field.rotationId
      })),
      ...cropRotationEntities
    }
  }
)

export const selectSelectedEntityOrRotation = createSelector(
  selectSelectedId,
  selectFarmTree,
  selectCropRotations,
  (selectedId, farmTree, cropRotations) => {
    const cropRotationEntities =
      formatCropRotationsAsEntities(cropRotations, farmTree) || {}
    const entities = {
      ...farmTree,
      ...cropRotationEntities
    }
    const selectedEntity = entities[selectedId]
    // rotation entity is a a completely different shape than farmTree entity.  Do not change these properties, they are required for consistency when fetching seasons
    const rotationId =
      selectedEntity?.type === ENTITY_TYPE_ROTATION_GROUP
        ? selectedEntity?.uuid
        : selectedEntity?.rotationId
    const selectedFieldId =
      selectedEntity?.type === ENTITY_TYPE_ROTATION_GROUP
        ? null
        : selectedEntity?.uuid
    return selectedEntity
      ? { ...selectedEntity, rotationId, selectedFieldId }
      : null
  }
)

export const selectPermissions = state => state.farmTree.permissions

export const selectBasicFarmTree = createSelector(selectFarmTree, entities => {
  const groups = pickBy(entities, { type: ENTITY_TYPE_ENTERPRISE_GROUPS })
  const farmsFields = pickBy(
    entities,
    ({ type }) => isFarm(type) || isField(type)
  )
  return size(groups) <= 1 ? farmsFields : { ...groups, ...farmsFields }
})

export const selectCroppingSeasonsForCleanup = createSelector(
  selectFarmTree,
  state => state.farmTree.croppingSeasonsForCleanup,
  (entities, croppingSeasonsForCleanup) => {
    return (
      croppingSeasonsForCleanup &&
      croppingSeasonsForCleanup.map(field => ({
        ...entities[field.uuid],
        ...field
      }))
    )
  }
)

export const selectFieldsWithFarmNameForRotation = (state, fieldIds) => {
  const fields = fieldIds.map(fieldId => {
    const parentUUID = state.farmTree.entities[fieldId].parentUUID
    const rotationId = state.farmTree.cropRotations.find(rotation => {
      return rotation.id === state.farmTree.entities[fieldId].rotationId
    })
    state.farmTree.entities[fieldId].farmName =
      state.farmTree.entities[parentUUID].name
    return {
      name: state.farmTree.entities[fieldId].name,
      farmName: state.farmTree.entities[fieldId].farmName,
      rotationName: rotationId.rotationName,
      id: fieldId
    }
  })
  return fields
}
